export const formatNumber = (value) => {
  const strValue = `${value}`;
  if (strValue.length > 6) {
    return `${strValue.slice(0, strValue.length - 5).split('').join(',')}млн`;
  }
  if (strValue.length > 3) {
    return `${strValue.slice(0, strValue.length - 3)}т`;
  }
  return value;
};

export const toLocale = (number) => parseInt(number, 10).toLocaleString();

export const getPollingStationNumber = (code) => parseInt(code.split('-')[2], 10);

export const separateDigits = (number, separator = ' ') => parseInt(number, 10).toLocaleString().split(',').join(separator);
