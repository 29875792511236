/* global ymaps */
import { getQuery, hasQuery, pushState } from './url';
import { DEFAULT_ZOOM, DEFAULT_CENTER, MAX_ZOOM } from './constants';

export const formatCoord = (x) => Number(x.toFixed(6));

export const syncCenterToUrl = (mapInstance) => {
  if (mapInstance.balloon.isOpen()) {
    // don't override url while station baloon is open
    return;
  }
  const z = mapInstance.getZoom();
  const coords = mapInstance.getCenter();
  const [lon, lat] = coords.map(formatCoord);
  pushState({ lon, lat, z });
};

export const getMap = () => {
  const { lon, lat, z = DEFAULT_ZOOM } = getQuery();
  const center = lon && lat ? [lon, lat] : DEFAULT_CENTER;

  return new ymaps.Map(
    'map',
    {
      center,
      zoom: z,
      controls: [],
      // behaviors: [
      //   'drag',
      //   'dblClickZoom',
      //   'multiTouch',
      //   'rightMouseButtonMagnifier',
      //   'leftMouseButtonMagnifier',
      // ],
    },
    {
      maxZoom: MAX_ZOOM,
    },
  );
};

export const getObjectManager = () => new ymaps.ObjectManager(
  {
    clusterize: true,
    clusterIconLayout: 'islands#blueStarCircleIcon',
    clusterIconPieChartRadius: 25,
    clusterIconPieChartCoreRadius: 10,
    clusterIconPieChartStrokeWidth: 3,
    hideIconOnBalloonOpen: false,
    clusterHasBalloon: false,
  },
  {
    showInAlphabeticalOrder: true,
  },
);

export const askGeolocation = ({ mapInstance }) => {
  let locationObject;
  ymaps.geolocation
    .get({
      autoReverseGeocode: false,
      provider: 'browser',
    })
    .then((res) => {
      mapInstance.geoObjects.remove(locationObject);
      locationObject = res.geoObjects;
      mapInstance.geoObjects.add(locationObject);

      if (!hasQuery()) {
        mapInstance.setCenter(res.geoObjects.position, 15);
        syncCenterToUrl(mapInstance);
      }
    })
    .catch((error) => {
      console.error(error);

      mapInstance.geoObjects.remove(locationObject);
      if (!hasQuery()) {
        mapInstance.setCenter(DEFAULT_CENTER, DEFAULT_ZOOM);
      }
    });
};

export const getOpenStation = ({ mapInstance, objectManager }) => (
  id,
  { setCenter = false } = {},
) => {
  const station = objectManager.objects.getById(id);
  if (setCenter) {
    mapInstance.setCenter(station.geometry.coordinates, MAX_ZOOM);
  }

  const { isClustered } = objectManager.getObjectState(id);
  if (isClustered) {
    objectManager.clusters.state.set('activeObject', id);
  }
};
