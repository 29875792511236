import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from './Table';

const useStyles = makeStyles((theme) => ({
  titleItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
    color: 'white',
    marginBottom: theme.spacing(5),
  },
  votingResultsItem: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function VotingResults({
  statistics,
  showPhotoData,
  showOfficialData,
  showIgnoreElection,
}) {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={9} className={classes.votingResultsItem}>
      <Grid
        container
        direction="row"
      >
        <Table
          choices={statistics.choices}
          statistics={statistics}
          showPhotoData={showPhotoData}
          showOfficialData={showOfficialData}
          showIgnoreElection={showIgnoreElection}
        />
      </Grid>
    </Grid>
  );
}
