import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { toLocale } from '~/helpers/numberFormatter';
import MapContainer from './Map/MapContainer';
import Point from '~/assets/images/point.svg';
import StationIconBlue from '~/assets/images/station-icon-blue.svg';
import StationIconRed from '~/assets/images/station-icon-red.svg';
import StationIconGray from '~/assets/images/station-icon-gray.svg';
import AsideItem from '~/components/AsideItem';

const useStyles = makeStyles((theme) => ({
  titleItem: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  legendItem: {
    '& span': {
      fontSize: '0.875rem',
    },
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  mapItem: {
    marginTop: theme.spacing(5),
  },
  numOfStations: {
    background: '#F0F0F0',
    color: 'black',
  },
}));

export default function PollingStationsMap() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      alignItems="flex-end"
    >
      <Grid item xs={12} sm={9}>
        <Grid
          container
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={12} sm={10} className={classes.titleItem}>
            <Typography
              component="h3"
              variant="h3"
            >
              {t('polling_stations')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <AsideItem
        last
        xs={12}
        sm={3}
        md={3}
        className={classes.numOfStations}
        image={
          <img width={35} src={Point} alt="Количество участков" />
        }
        text={(
          <>
            <Typography
              component="span"
              variant="h3"
            >
              {toLocale(5767)}
            </Typography>
            <br />
            <Typography
              component="span"
            >
              {t('stations')}
            </Typography>
          </>
        )}
      />

      <Grid item xs={12} sm={9}>
        <Grid
          container
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={12} sm={10} className={classes.legendItem}>
            <img width="15" src={StationIconBlue} alt="" style={{ marginRight: '8px' }} />
            <Typography component="span">
              {t('honest_stations')}
            </Typography>
            <br />

            <img width="15" src={StationIconRed} alt="" style={{ marginRight: '8px' }} />
            <Typography component="span">
              {t('dishonest_stations')}
            </Typography>
            <br />

            <img width="15" src={StationIconGray} alt="" style={{ marginRight: '8px' }} />
            <Typography component="span">
              {t('not_enough_data')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.mapItem}>
        <MapContainer />
      </Grid>
    </Grid>
  );
}
