import React from 'react';
import {
  Grid,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ButtonBlue from '../../components/ButtonBlue';
import WalkingSticker from '~/assets/images/walking/nina_baginskaya.png';
import { goEngUrl } from '../../../globals';

// import Counter from '~/components/Walking/Counter';
// import { separateDigits } from '~/helpers/numberFormatter';
// import { previousSundayDate, walkFormat, goOutFormat } from '~/helpers/countersTexts';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray,
    paddingBottom: theme.spacing(7),
  },
  imageItem: {
    paddingTop: theme.spacing(8),
  },
  counters: {
    paddingTop: theme.spacing(8),
  },
  totalTitle: {
    lineHeight: 1.3,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  iWalkHomeTitle: {
    textAlign: 'right',
    color: '#929292',
    lineHeight: 1.3,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      textAlign: 'left',
    },
  },
  figure: {
    margin: 0,
    maxWidth: '420px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  author: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  padding: {
    paddingLeft: '13.111113%',
    paddingRight: '8.111113%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  paddingCounters: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  paddingImage: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(5),
    },
  },
  iWalkHome: {
    textAlign: 'right',
    color: '#929292',

    [theme.breakpoints.up('xs')]: {
      fontSize: '1.9rem',
      lineHeight: '1.9rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4rem',
      lineHeight: '2.4rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4.7rem',
      lineHeight: '4.7rem',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  solidarytyDayAnnounce: {
    marginTop: theme.spacing(3),
  },
  bold: {
    fontWeight: '600',
  },
  biggerText: {
    fontSize: '1.6rem',
    letterSpacing: 'normal',
    lineHeight: '2.0rem',
  },
  desc: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.13rem',
      marginTop: theme.spacing(5),
    },
  },
}));

// eslint-disable-next-line no-unused-vars
export default function Counters({ totalVoted, totalWalking, SupportersAtHome }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  return (
    <Grid
      container
      className={classes.root}
    >
      <Grid item xs={12} className={classes.padding}>
        <Grid
          container
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.paddingImage, classes.imageItem)}
          >
            <figure className={classes.figure}>
              <img
                className={classes.image}
                src={WalkingSticker}
                alt="Я гуляю - Нина Багинская, рисунок"
              />
              <figcaption>
                <Typography
                  component="span"
                  variant="body1"
                >
                  {t('image_author')}
                  &nbsp;
                  <Typography
                    component="span"
                    variant="body1"
                  >
                    <Link
                      href="https://www.instagram.com/rozan_art"
                      rel="noreferrer"
                      target="_blank"
                      underline="always"
                      className={classes.author}
                    >
                      rozan_art
                    </Link>
                  </Typography>
                </Typography>
              </figcaption>
            </figure>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.counters, classes.paddingCounters)}
          >
            <Typography
              component="h2"
              variant="h3"
              color="primary"
              className={classes.totalTitle}
            >
              {t('us_already')}
            </Typography>

            <Counter total={totalVoted} type="blue" position="right"/>

            <Box mt={6}/>

            <Typography
              component="h2"
              variant="h3"
              color="primary"
              className={classes.totalTitle}
              id="grey"
            >
              {t('walking_today', {
                GoOut: goOutFormat(currentLanguage),
                Sunday: previousSundayDate(currentLanguage),
                Walk: walkFormat(currentLanguage),
              })}

            </Typography>

            <Counter total={totalWalking} type="blue" position="right"/>

            <Box mt={6}/>

            <Typography
              component="h2"
              variant="h3"
              color="primary"
              className={classes.iWalkHomeTitle}
            >
              {t('walking_home', {
                GoOut: goOutFormat(currentLanguage),
                Sunday: previousSundayDate(currentLanguage),
                Walk: walkFormat(currentLanguage),
              })}
            </Typography>

            <Typography
              component="h2"
              variant="h3"
              color="primary"
              className={classes.iWalkHome}
            >
              {separateDigits(SupportersAtHome)}
            </Typography>
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.counters, classes.paddingCounters)}
          >
            <Typography
              component="p"
              className={clsx(classes.solidarytyDayAnnounce, classes.biggerText)}
            >
              <Trans
                i18nKey="solid_day_announce_big"
                components={{ bold: <Typography component="span" className={clsx(classes.bold, classes.biggerText)} /> }}
              />
            </Typography>
            <Typography
              component="p"
              className={classes.solidarytyDayAnnounce}
            >
              <Trans
                i18nKey="solid_day_announce_small"
              />
            </Typography>
            <Box className={classes.desc}>
              <ButtonBlue href={goEngUrl} smallfont>
                {t('button_support_belarus')}
              </ButtonBlue>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
Counters.defaultProps = {
  totalVoted: 0,
  totalWalking: 0,
  SupportersAtHome: 0,
};

Counters.propTypes = {
  totalVoted: PropTypes.number,
  totalWalking: PropTypes.number,
  SupportersAtHome: PropTypes.number,
};
