import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ImageTabPanel from './ImageTabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.primary.main,
  },
  tabs: {
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      flexBasis: '100%',
      maxWidth: '100%',
    },
    '& .Mui-selected': {
      color: theme.palette.white,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  views: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '-85px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '-48px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-66px',
    },
  },
  tab: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    background: theme.palette.white,
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    opacity: 1,
    fontWeight: '600',
    transition: 'all 0.3s ease',
    '&:not(:first-child)': {
      borderLeft: 0,
    },
    '&:hover': {
      color: theme.palette.primary.mainDark,
      background: theme.palette.primary.main,
      textDecoration: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '1rem',
      lineHeight: '1rem',
      padding: '10px 10px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '10px 25px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      padding: '20px 50px',
    },
  },
  indicator: {
    background: 'none',
  },
  selectContainer: {
    zIndex: 1,
    padding: '15px 0 15px 40px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  select: {
    background: theme.palette.white,
    padding: '8px 0 8px 13px',
  },
}));

const SwipeableImageTabs = (props) => {
  const classes = useStyles(props);
  const { tabs, defaultIndex } = props;
  const [value, setValue] = React.useState(defaultIndex);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    handleChangeIndex(newValue);
  };

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} md={9} className={classes.tabs}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
            }}
          >
            {tabs.map(({ title, id }, index) => (
              <Tab
                label={t(title)}
                key={id}
                className={classes.tab}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={9} className={classes.selectContainer}>
          <Select
            labelId="swipeable-tab-select-label"
            id="swipeable-tab-select"
            onChange={handleSelectChange}
            value={value}
            className={classes.select}
          >
            {tabs.map(({ title, id }, index) => (
              <MenuItem key={id} value={index}>
                <Typography
                  className={classes.menuItem}
                >
                  {t(title)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.views}
        >
          {tabs.map(({ image, id }, index) => (
            <ImageTabPanel image={image} index={index} key={id} />
          ))}
        </SwipeableViews>
      </Grid>
    </Container>
  );
};

SwipeableImageTabs.defaultProps = {
  defaultIndex: 0,
};

SwipeableImageTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape(
      {
        srcDefault: PropTypes.shape({
          url: PropTypes.string.isRequired,
          width: PropTypes.number.isRequired,
        }),
        srcSet: PropTypes.arrayOf(PropTypes.any),
      },
    ).isRequired,
  })).isRequired,
  defaultIndex: PropTypes.number,
};

export default SwipeableImageTabs;
