import React from 'react';
import {
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  indexOf,
} from 'lodash';
import 'react-image-gallery/styles/css/image-gallery.css';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CheckIcon from '~/assets/images/check-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  voteForText: {
    display: 'inline-block',
    marginTop: '4px',
    marginRight: theme.spacing(2),
    fontWeight: 600,
  },
  personal: {
    maxWidth: '400px',
  },
}));

export default function BulletinInfo({ bulletin, quarantinedBulletins }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const getCandidateAccusativeName = () => t(`${bulletin.candidateId}Accusative`);

  const showLink = () => {
    const isMobile = useMediaQuery('(max-width: 767px)');

    if (!isMobile) return false;

    if (bulletin.secondaryImage && bulletin.secondaryImage !== 'none') {
      const imageFileName = bulletin.secondaryImage.slice(bulletin.secondaryImage.lastIndexOf('/') + 1);

      if (indexOf(quarantinedBulletins, imageFileName) !== -1) {
        return false;
      }

      return true;
    }

    return false;
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      spacing={1}
      className={classes.root}
    >
      <Grid item>
        <img height="25" src={CheckIcon} alt="Ballot" />
      </Grid>

      <Grid item xs>
        <Typography
          component="span"
          className={classes.voteForText}
        >
          {
            bulletin.candidateId === 'corrupted'
              ? t('destroyed_ballot')
              : `${t('voted_for')} ${getCandidateAccusativeName()}`
          }
        </Typography>

        {showLink() === true
          ? (
            <>
              <br />
              <Link
                variant="body1"
                href={bulletin.secondaryImage}
                color="primary"
                target="_blank"
                underline="always"
                style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
              >
                {t('back_side')}
              </Link>
            </>
          )
          : null}
      </Grid>
    </Grid>
  );
}

BulletinInfo.propTypes = {
  bulletin: PropTypes.objectOf(PropTypes.string).isRequired,
};
