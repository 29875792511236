import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  padding: {
    paddingLeft: '13.111113%',
    paddingRight: '8.111113%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

export default function HeaderCall({ children }) {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.padding}>
        {children}
      </Grid>
    </Grid>
  );
}
