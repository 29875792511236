import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getTotalVoted } from '~/services/ResultsService';
import { getResults } from '~/redux/selectors';
import { toLocale } from '~/helpers/numberFormatter';
import AllStatistics from './AllStatistics';
import Loading from '~/components/Loading';
import PollingStationsMap from './PollingStationsMap';
import VotingResults from '~/components/VotingResults/VotingResults';
import Summary from './Summary';
import HonestLogo from '~/assets/images/honestLogoItem.svg';
import Logo from '~/assets/images/logoItem.svg';
import PhotosDisclaimer from './PhotosDisclaimer';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingTop: theme.spacing(5),
  },
  empty: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  detailedReport: {
    marginTop: theme.spacing(5),
    background: '#f0f0f0',
  },
  image: {
    marginTop: theme.spacing(0.6),
  },
  titleItem: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    marginBottom: theme.spacing(5),
  },
}));

function StatisticsContainer({ statistics }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [totalVoted, setTotalVoted] = useState(null);
  const [totalVotedLoaded, setTotalVotedLoaded] = useState(false);

  useEffect(() => {
    getTotalVoted().then(
      (_totalVoted) => {
        setTotalVoted(_totalVoted);
        setTotalVotedLoaded(true);
      },
      () => {
        setTotalVotedLoaded(true);
      },
    );
  }, []);

  return (
    <Container component="main" maxWidth={false} disableGutters>
      {
        statistics && totalVotedLoaded
          ? <Summary totalVoted={totalVoted} statistics={statistics} />
          : <Loading />
      }

      <Grid container>
        {
          statistics
            ? (
              <>
                <Grid item xs={12} md={9} className={classes.results}>
                  <Grid
                    container
                    alignItems="flex-start"
                  >
                    <Grid item md={2}>
                      {/* Empty */}
                    </Grid>

                    <Grid item xs={12} sm={10} className={classes.titleItem}>
                      <Typography
                        component="h3"
                        variant="h3"
                      >
                        {`${toLocale(statistics.registered)} ${t('unique_confirmed')}:`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <VotingResults
                  statistics={statistics}
                  showPhotoData
                  showOfficialData={false}
                  showIgnoreElection
                />
              </>
            ) : <Loading />
        }

        {
          statistics && totalVotedLoaded
            ? <AllStatistics totalVoted={totalVoted} statistics={statistics} />
            : <Loading />
        }
      </Grid>

      <PollingStationsMap />

      <PhotosDisclaimer />

      <Grid container>
        <Grid item xs={12} md={9} className={classes.detailedReport}>
          <Grid
            container
            alignItems="flex-start"
          >
            <Grid item md={2}>
              {/* Empty */}
            </Grid>

            <Grid item xs={10} className={classes.titleItem}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item>
                  <img width="35" height="35" src={Logo} className={classes.image} alt="Голос" style={{ marginRight: '10px' }} />
                  <img width="35" height="35" src={HonestLogo} className={classes.image} alt="Честные Люди" />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Trans
                    i18nKey="detailed_report"
                    components={{ bold: <Typography component="span" variant="h3" /> }}
                  />
                  <br />
                  <Typography
                    component="span"
                    variant="body1"
                  >
                    {t('detailed_report_from')}
                    &nbsp;
                    <Typography component="span" variant="h4">
                      <Link
                        href="http://bit.ly/golos-final-report"
                        rel="noreferrer"
                        target="_blank"
                        underline="always"
                      >
                        {t('here')}
                      </Link>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
StatisticsContainer.defaultProps = {
  statistics: null,
};

StatisticsContainer.propTypes = {
  statistics: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  statistics: getResults(state),
});

export default connect(mapStateToProps)(StatisticsContainer);
