import React from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '3px 3px 0 0 #1467a0',
      color: '#1467a0',
    },
  },
  results: {
    '& .MuiTypography-body1': {
      lineHeight: '1.5rem',
    },
  },
  titleItem: {
    color: 'white',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  link: {
    color: '#000',
    backgroundColor: 'white',
    textDecoration: 'none',
    width: '180px',
    height: '70px',
    borderRadius: '50px',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '18px',
    '& img': {
      margin: '0 10px',
    },
    border: '2px solid #54B6F9',
  },
  summary: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  body: {
    color: 'white',
    fontSize: '18px',
  },
}));

export default function Summary({ statistics }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid
      container
      className={classes.root}
    >
      <Grid item xs={12} md={9} className={classes.results}>
        <Grid
          container
          alignItems="flex-start"
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={10} className={classes.titleItem}>
            <Typography
              component="h3"
              variant="h3"
            >
              {t('summary_results')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} className={classes.sectionTitle}>
        {/* Empty */}
      </Grid>

      <Grid item xs={12} md={9} className={classes.summary}>
        <Grid
          container
          alignItems="flex-start"
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={10}>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography
                    variant="body1"
                    className={classes.body}
                  >
                    <Trans
                      i18nKey="summary_1"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <Typography
                    variant="body1"
                    className={classes.body}
                  >
                    <Trans
                      i18nKey="summary_2"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <Typography
                    variant="body1"
                    className={classes.body}
                  >
                    <Trans
                      i18nKey="summary_3"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemText>
                  <Typography
                    variant="body1"
                    className={classes.body}
                  >
                    <Trans
                      i18nKey="summary_4"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>

            <Box mt={4.5}>
              <Button
                variant="contained"
                rel="noreferrer"
                target="_blank"
                disableElevation
                className={classes.button}
                href={i18n.language === 'en'
                  ? 'https://bit.ly/voice-belarus-report'
                  : 'http://bit.ly/golos-final-report'}
                endIcon={<OpenInNewIcon />}
                size="large"
              >
                {t('detailed_report_button')}
              </Button>

              <Button
                variant="contained"
                rel="noreferrer"
                target="_blank"
                disableElevation
                className={classes.button}
                href="https://bit.ly/golos_final_data"
                endIcon={<OpenInNewIcon />}
                size="large"
              >
                {t('results_button')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
