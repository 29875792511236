/* global ymaps */

export const getSearchControl = () => new ymaps.control.SearchControl({
  options: {
    float: 'left',
    floatIndex: 1,
    noPlacemark: true,
    maxWidth: [30, 72, 315],
  },
});

export const getZoomControl = () => new ymaps.control.ZoomControl({
  options: {
    position: {
      right: 10,
      top: 50,
    },
  },
});

export const getGeolocationControl = () => new ymaps.control.GeolocationControl({
  options: {
    position: {
      right: 10,
      top: 270,
    },
  },
});
