import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { nextSundayDate } from '~/helpers/countersTexts';
// eslint-disable-next-line import/no-unresolved,import/extensions
import HeaderCall from '~/components/HeaderCall';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  header: {
    color: 'white',
    textTransform: 'uppercase',
    marginBottom: '0',
  },
  paddingSM: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

export default function SupportBelarusCall() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  return (
    <HeaderCall>
      <Typography
        component="h1"
        variant="h3"
        className={classes.header}
      >
        {/* {t('support_belarus_call_header', { */}
        {/*  Sunday: nextSundayDate(currentLanguage), */}
        {/* })} */}
        {t('walking_call_header')}
      </Typography>
    </HeaderCall>
  );
}
