import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Grid,
  Typography,
  Container,
  Link,
} from '@material-ui/core';
import {
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Logo from '~/assets/images/logo.svg';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import { goPath, goEngPath, landingUrl } from '../../globals';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  logo: {
    '& a': {
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      width: 90,
      height: 30,
    },
  },
  voice: {
    marginRight: theme.spacing(2.5),
    display: 'inline-block',
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(3.7),
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hashTag: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  hashTagUpXs: {
    display: 'inline',
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hashTagDownXs: {
    fontSize: '0.8rem',
    display: 'none',
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(1),
    },
  },
  logoItem: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      marginRight: 0,
      display: 'flex',
      flexGrow: 1,
    },
  },
  languageItem: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(5),
      textAlign: 'right',
    },
  },
  appbar: {
    paddingTop: theme.spacing(5.7),
    paddingBottom: theme.spacing(5.7),
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}));


export default function AppBar() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const selectLanguage = (value) => { i18n.changeLanguage(value); };
  const selectedLanguage = i18n.language || window.localStorage.i18nextLng;
  const langs = location.pathname && location.pathname === goEngPath ? ['en', 'ru'] : ['by', 'ru', 'en'];
  const hashTagTitle = {
    [goPath]: t('go_hashtag'),
    [goEngPath]: t('go_eng_hashtag'),
    '/': t('we_have_voice_hashtag'),
  };
  const hashTagUrl = {
    [goPath]: 'https://www.instagram.com/explore/tags/ягуляю',
    [goEngPath]: 'https://www.instagram.com/explore/tags/supportbelarus',
    '/': 'https://www.instagram.com/explore/tags/унасестьголос',
  };

  return (
    <MuiAppBar position="static" elevation={0} color="default" className={classes.appbar}>
      <Container disableGutters maxWidth={false}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
          className={classes.container}
        >
          <Grid item xs md={9} className={classes.logoItem}>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
            >
              <Grid item md={2}>
                {/* Empthy */}
              </Grid>

              <Grid item md={10}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                  <Link href={landingUrl}>
                      <img width="148" height="53" src={Logo} alt="Голос" className={classes.logo} />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={landingUrl}>
                      <Typography
                        className={classes.voice}
                        variant="h1"
                        component="h1"
                      >
                        {t('voice').toUpperCase()}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <a className={classes.link} href={hashTagUrl[location.pathname] || hashTagUrl['/']} rel="noreferrer" target="_blank">
                      <Typography
                        className={clsx(classes.hashTag, classes.hashTagUpXs)}
                        component="span"
                      >
                        {hashTagTitle[location.pathname] || hashTagTitle['/']}
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs md={3} item className={classes.languageItem}>
            <LanguageSelect
              onSelect={selectLanguage}
              selected={selectedLanguage}
              langs={langs}
            />

            <a className={classes.link} href={hashTagUrl[location.pathname] || hashTagUrl['/']} rel="noreferrer" target="_blank">
              <Typography
                className={clsx(classes.hashTag, classes.hashTagDownXs)}
                component="span"
              >
                {hashTagTitle[location.pathname] || hashTagTitle['/']}
              </Typography>
            </a>
          </Grid>
        </Grid>
      </Container>
    </MuiAppBar>
  );
}
