import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveImage from './ResponsiveImage';

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    height: '800px',
    width: '100%',
    '& img': {
      objectFit: 'cover',
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    [theme.breakpoints.up('xs')]: {
      height: '360px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '500px',
    },
    [theme.breakpoints.up('md')]: {
      height: '669px',
    },
  },
}));

const ImageTabPanel = (props) => {
  const {
    image, index,
  } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.image}
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      <ResponsiveImage
        altTKey={`tabpanel-image-${index}`}
        srcDefault={image.srcDefault}
        srcSet={image.srcSet}
      />
    </Box>
  );
};

ImageTabPanel.propTypes = {
  image: PropTypes.shape(
    {
      srcDefault: PropTypes.shape({
        url: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
      }),
      srcSet: PropTypes.arrayOf(PropTypes.any),
    },
  ).isRequired,
  index: PropTypes.number.isRequired,
};

export default ImageTabPanel;
