import React from 'react';
import {
  Link,
} from '@material-ui/core';

export default function BulletinImage({ link, isClickable, className }) {
  if (!isClickable) return <img className={className} src={link} alt="" />;

  return (
    <Link
      variant="body1"
      href={link}
      color="primary"
      target="_blank"
      style={{ flex: 1 }}
    >
      <img className={className} src={link} alt="" />
    </Link>
  );
}
