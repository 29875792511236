import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

export default function Link({ children, to, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiLink component={RouterLink} to={to} {...props}>
      {children}
    </MuiLink>
  );
}

Link.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
};
