import Against from '~/assets/images/choices/against-all.svg';
import Cherechen from '~/assets/images/choices/cherechen.png';
import Dmitriev from '~/assets/images/choices/dmitriev.png';
import Kanopatskaya from '~/assets/images/choices/konopatskaya.png';
import Lukashenko from '~/assets/images/choices/lukashenko.png';
import Spoilt from '~/assets/images/choices/spoilt.svg';
import Tihanovskaya from '~/assets/images/choices/tihanovskaja.png';
import StayHome from '~/assets/images/choices/stay-home.svg';

export default function getAll() {
  return [
    {
      id: 'dmitriyev',
      img: Dmitriev,
    },
    {
      id: 'kanopatskaja',
      img: Kanopatskaya,
    },
    {
      id: 'lukashenko',
      img: Lukashenko,
    },
    {
      id: 'tihanovkaja',
      img: Tihanovskaya,
    },
    {
      id: 'cherechen',
      img: Cherechen,
    },
    {
      id: 'against',
      img: Against,
    },
    {
      id: 'corrupted',
      img: Spoilt,
    },
    {
      id: 'ignore',
      img: StayHome,
    },
  ];
}
