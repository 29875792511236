import actionTypes from './actionTypes';

export function loadPollingStations(pollingStations) {
  return {
    type: actionTypes.LOAD_POLLING_STATIONS,
    payload: pollingStations,
  };
}

export function loadWhitelist(whitelist) {
  return {
    type: actionTypes.LOAD_WHITELIST,
    payload: whitelist,
  };
}

export function loadResults(results) {
  return {
    type: actionTypes.LOAD_RESULTS,
    payload: results,
  };
}

export function loadDetached(detached) {
  return {
    type: actionTypes.LOAD_DETACHED,
    payload: detached,
  };
}
