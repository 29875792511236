import React, { useState, useEffect } from 'react';
import {
  Container,
} from '@material-ui/core';
import { getTotalVoted } from '~/services/ResultsService';
import WalkingCall from './WalkingCall';
import WalkingResult from '../AllResults/WalkingResult';
import SupportBelarus from './SupportBelarus';
// import Steps from './Steps';
import Counters from './Counters';
import Loading from '~/components/Loading';
import SwipeableImageTabs from '../../components/SwipeableImageTabs';
import WalkResultsBy960 from '~/assets/images/walking/walk-results-by-960.jpg';
import WalkResultsBy1440 from '~/assets/images/walking/walk-results-by-1440.jpg';
import WalkResultsBy1920 from '~/assets/images/walking/walk-results-by-1920.jpg';
import WalkResultsEu960 from '~/assets/images/walking/walk-results-eu-960.jpg';
import WalkResultsEu1440 from '~/assets/images/walking/walk-results-eu-1440.jpg';
import WalkResultsEu1920 from '~/assets/images/walking/walk-results-eu-1920.jpg';
import WalkResultsWorld960 from '~/assets/images/walking/walk-results-world-960.jpg';
import WalkResultsWorld1440 from '~/assets/images/walking/walk-results-world-1440.jpg';
import WalkResultsWorld1920 from '~/assets/images/walking/walk-results-world-1920.jpg';

const walkingResultConfig = [
  {
    id: 'belarus',
    title: 'walking_by',
    image: {
      srcDefault: {
        url: WalkResultsBy1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsBy960,
          width: 960,
        },
        {
          url: WalkResultsBy1920,
          width: 1920,
        },
      ],
    },
  },
  {
    id: 'europe',
    title: 'walking_eu',
    image: {
      srcDefault: {
        url: WalkResultsEu1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsEu960,
          width: 960,
        },
        {
          url: WalkResultsEu1920,
          width: 1920,
        },
      ],
    },
  },
  {
    id: 'world',
    title: 'walking_world',
    image: {
      srcDefault: {
        url: WalkResultsWorld1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsWorld960,
          width: 960,
        },
        {
          url: WalkResultsWorld1920,
          width: 1920,
        },
      ],
    },
  },
];

function WalkingContainer() {
  const [total, setTotal] = useState(null);
  const [totalLoaded, setTotalLoaded] = useState(false);

  useEffect(() => {
    getTotalVoted().then(
      (_totalVoted) => {
        setTotal(_totalVoted);
        setTotalLoaded(true);
      },
      () => {
        setTotalLoaded(true);
      },
    );
  }, []);

  return (
    <Container component="main" maxWidth={false} disableGutters>
      <WalkingCall />
      {
        totalLoaded
          ? (
            <>
              <Counters
                totalVoted={total.TotalVoted || 0}
                totalWalking={total.TotalWalking || 0}
                SupportersAtHome={total.SupportersAtHome || 0}
              />
              <WalkingResult title="walking_result_title" />
              <SwipeableImageTabs tabs={walkingResultConfig} />
              <SupportBelarus SupportersAbroad={total.SupportersAbroad || 0} />
              {/* <Steps totalWalking={total.TotalWalking || 0} /> */}
            </>
          )
          : <Loading />
      }
    </Container>
  );
}

export default WalkingContainer;
