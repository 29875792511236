import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrowButton: {
    padding: '0',
  },
  arrowIcon: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },
  },
  stepStatus: {
    alignSelf: 'center',
    fontWeight: 'bold',
    minWidth: '2.5ch',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
}));

export default function PollingStationStepper({
  activeStep, steps, onNext, onBack, className,
}) {
  const classes = useStyles();
  return (
    <Box display="flex" className={className}>
      <IconButton
        onClick={onBack}
        disabled={activeStep === 0}
        className={classes.arrowButton}
      >
        <KeyboardArrowLeft className={classes.arrowIcon} />
      </IconButton>
      <Typography className={classes.stepStatus}>
        {activeStep + 1}
        /
        {steps}
      </Typography>
      <IconButton
        onClick={onNext}
        disabled={activeStep === steps - 1}
        className={classes.arrowButton}
      >
        <KeyboardArrowRight className={classes.arrowIcon} />
      </IconButton>
    </Box>
  );
}

PollingStationStepper.defaultProps = {
  className: null,
};

PollingStationStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  className: PropTypes.string,
};
