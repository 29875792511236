const ru = {
  translations: {
    polling_stations: 'Избирательные участки',
    результаты: 'Предварительные результаты голосования',
    voice: 'голос',
    with_photo: 'с фото',
    according_to_CEC: 'по версии УИК',
    voters: 'избирателей',
    unique_confirmed: 'уникальных подтвержденных голосов на платформе "Голос"',
    'фото в системе': 'проверенных бюллетеней на платформе "Голос"',
    social_media_follow: 'Присоединяйтесь к нам в соцсетях и мессенджерах.',
    tell_friends: 'Приглашайте друзей и знакомыx!',
    пользовательское: 'Пользовательское соглашение',
    faq: 'Ответы на популярные вопросы',
    stations: 'участков',
    'официальная явка': 'явка избирателей согласно протоколу УИК',
    according_to_observers: 'по данным наблюдателей',
    honest_people_hashtag: '#мыЧестныеЛюди',
    we_have_voice_hashtag: '#уНасЕстьГолос',
    all: 'Все',
    dmitriyev: 'Дмитриев',
    kanopatskaja: 'Канопацкая',
    lukashenko: 'Лукашенко',
    tihanovkaja: 'Тихановская',
    cherechen: 'Черечень',
    against: 'Против всех',
    corrupted: 'Испортил(а) бюллетень',
    ignore: 'Не ходил(а) на выборы',
    dmitriyevAccusative: 'за Дмитриева',
    kanopatskajaAccusative: 'за Канопацкую',
    lukashenkoAccusative: 'за Лукашенко',
    tihanovkajaAccusative: 'за Тихановскую',
    cherechenAccusative: 'за Черечня',
    againstAccusative: 'против всех',
    corruptedAccusative: 'Испортил(а) бюллетень',
    ignoreAccusative: '',
    pollingStationDetails: 'подробнее',
    voices_plural: 'голоса',
    voices_plural_stats: 'голосов',
    voted_for: 'Этот голос был отдан',
    back_side: 'Обратная сторона',
    honest_stations: 'Победа кандидата совпадает в протоколе комиссии и данных "Голоса"',
    dishonest_stations: 'Голосов за кандидата в "Голосе" больше, чем в официальном протоколе (фальсификация)',
    not_enough_data: 'На участках недостаточно данных для вывода о честности работы избирательной комиссии',
    total_num_of_voices: 'всего голосов',
    участок: 'Участок',
    all_stations: 'Все участки',
    область: 'область',
    title: 'Онлайн-платформа "Голос". В этот раз ваш голос решает всё!',
    processing_monitored: 'Данные от наблюдателей обрабатываются',
    processing_voters: 'Данные об избирателях обрабатываются',
    processing_official_numbers: 'Данные итогового протокола не были вывешены или сфотографированы',
    processing_official_numbers_table: 'Нет данных',
    protect_now: 'Защитите ваш голос сейчас!',
    more_ballots_than_official: '"Голосов" больше, чем голосов по версии УИК',
    more_photos_than_official: 'Фото бюллетеней больше, чем голосов по версии УИК',
    destroyed_ballot: 'Бюллетень испорчен',
    summary_results: 'Результаты голосования',
    detailed_report_button: 'Детальный отчёт',
    results_button: 'Протоколы УИК',
    summary_1: 'Выборы недействительны, поскольку фальсификации были обнаружены на каждом третьем избирательном участке.',
    summary_2: 'Итоговые данные, озвученные ЦИК, значительно расходятся с официальными данными из участковых протоколов.',
    summary_3: 'Александр Лукашенко не может считаться легитимным президентом Беларуси.',
    summary_4: 'Большое количество голосов, отданных за Светлану Тихановскую, украдены участковыми комиссиями.',
    chatbot_send_photo: 'Пришлите чат-боту платформы "Голос" фотографию вашего бюллетеня',
    detailed_report: '<bold>Подробный отчёт</bold>',
    detailed_report_from: 'от платформы "Голос" и инициативы "Честные Люди" ––',
    here: 'здесь',
    no_photos: 'Фото бюллетеней обрабатываются',
    no_photos_available: 'Фотографии за данного кандидата отсутствуют',
    sensitive_data: 'Фото скрыто, так как на нём может присутствовать паспорт избирателя или другие личные данные. Оригинал фото есть в распоряжении платформы "Голос". Всего на платформе 45,865 таких фотографий из {{photos}} (менее 10%)',
    photo_quarantined: 'Фото временно скрыто, так как проходит дополнительную обработку',
    report_personal_data: 'Мы скрыли личные данные (паспорт или лицо) с большей части фотографий, содержащих их. Если вы всё же заметили фото с личными данными или неверно распознанное, сообщите нам, нажав на кнопку "Скрыть фото" внизу.',
    PEC_results: 'Протокол',
    send_results_photo: 'Прислать фото протокола',
    station_code: 'Код участка',
    photos_disclaimer: 'Фотографии протоколов мы получили от независимых наблюдателей инициатив "Честные люди", "Зубр", а также из открытых источников. Мы оцениваем точность данных в 98%. Если вы обнаружите неточность или ошибку, сообщите нам об этом через',
    this_form: 'эту форму',
    site_title: 'Результаты платформы Голос: найди свой участок!',
    site_description: 'Поможем защитить ваш выбор и покажем результаты, подсчитанные не комиссиями, а избирателями. Заходите в Viber, Telegram, расскажите о своём выборе!',
    error_notify: 'Сообщить об ошибке',
    notify: 'Скрыть фото',
    notify_personal_data: 'Содержит личные данные',
    notify_incorrect_data: 'Неправильный кандидат',
    notify_other: 'Другая проблема',
    problem: 'Проблема',
    state_problem: 'Опишите проблему',
    report_manually: 'Если по какой-то причине форма выше не работает, отправьте ошибку вручную через',
    missing_photo1: 'Не можете найти свою фотографию? ',
    missing_photo2: 'На платформе "Голос" содержится ',
    missing_photo3: '{{registered}} голосов',
    missing_photo4: ', не привязанных к участку, из них {{photoVoices}} –– с фото.',
    // walking_call_header: 'Гуляйте в хорошей компании',
    walking_call_header: '20 СЕНТЯБРЯ — ДЕНЬ СОЛИДАРНОСТИ С БЕЛАРУСЬЮ',
    solid_day_announce_big: '<bold>20\u00A0сентября</bold> "Голос" присоединяется к глобальному Дню солидарности с Беларусью.  Присоединяйтесь к акции поддержки нашей страны, нажав кнопку "Поддержать белорусов".',
    solid_day_announce_small: 'Мы приостановили команду /ягуляю в чат-ботах на время проведения опроса в поддержку Координационного Совета и Дня солидарности.<br /><br />В воскресенье, 20 сентября, и белорусы, и иностранцы могут поддержать протесты в Беларуси на нашей специальной страничке.',
    button_support_belarus: 'ПОДДЕРЖАТЬ БЕЛОРУСОВ',
    walking_call_button: 'Как это работает?',
    us_already: 'Зарегистрировано в "Голосе"',
    walking_today: '{{Walk}} на улице в Беларуси {{Sunday}}',
    walking_home: '{{Walk}} дома в Беларуси {{Sunday}}',
    image_author: 'автор иллюстрации:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Шаг 1: Зарегистрируйтесь в\u00A0"Голосе"',
    walking_title_step_2: 'Шаг 2: Сообщите чат-боту, что вы гуляете',
    walking_title_step_3: 'Шаг 3: Поделитесь прогулкой с\u00A0друзьями',
    walking_activate_bot: 'Активируйте бота перед прогулкой',
    walking_activate_bot_desc_1: 'Перед выходом на прогулку активируйте чат-бота: отправьте ему команду <bold>/ягуляю</bold>. Так вы сообщите "Голосу", что в конкретный день присоединились к протесту. Хотите пойти гулять, но не можете — все равно отправляйте команду.',
    walking_activate_bot_desc_2: 'Участие в законных масштабных маршах, локальные митинги, цепочки солидарности, любые инициативы, да даже прогулка во дворе и разговор с соседями — форма протеста не имеет значения. Главное, что вы активны и наш чат-бот об этом знает. Платформе не нужны ваши персональные данные — мы хотим только посчитать участников протеста.',
    walking_activate_bot_desc_3: 'Устраиваем подсчёт каждое воскресенье и в специальные дни, о которых будем сообщать отдельно.',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Используйте хэштег #ягуляю',
    walking_use_hashtag_desc_1: 'Чем нас больше — тем громче наш голос. Властям будет сложнее и дальше делать вид, что нас не слышно. Фотографируйтесь во время своих прогулок, показывайте в соцсетях, ставьте хэштег <bold>#ягуляю</bold>.',
    walking_use_hashtag_desc_2: 'Расскажите о проекте всем знакомым. Наш счётчик — доказательство нашей правды и нашей победы.',
    walking_how_title: 'Как это работает',
    walking_how_desc_1: 'Новый спецпроект "Голоса" — <bold>#ягуляю</bold>. Теперь мы хотим посчитать белорусов, которые выходят на протесты, отстаивают свободу и новые честные выборы. Мы хотим показать всему миру реальную картину борьбы за справедливость.',
    walking_how_desc_2: '<bold>#ягуляю</bold> будет считать всех: кто законно гуляет в Минске и крупных городах, кто гуляет в отдалённых уголках Беларуси в поддержку протестующих, кто объективно не может гулять, но тоже против беззакония.',
    walking_how_desc_3: 'Это анонимно и безопасно. Присоединиться может каждый. Даже тот, кто хочет поддержать протест, но пока не решается делать это открыто.',
    walking_how_desc_4: 'В "Голосе" уже более <bold>1 200 000</bold> человек. И сейчас мы возобновляем регистрацию на платформе.',
    walking_how_desc_5: 'Поставили бота? Поделитесь нашей идеей с друзьями!',
    walking_how_desc_6: '<bold>Если вы уже зарегистрированы в чат-боте, переходите к следующему шагу.</bold>',
    walking_how_desc_7: '<bold>Если регистрируетесь впервые — нажимайте на кнопку любимого мессенджера и следуйте инструкциям в чат-боте. На первом шаге подтвердите отправку номера телефона (он будет надежно зашифрован).</bold>',
    no_messenger: 'Если у вас нет мессенджера',
    faq_answers: 'Ответы на часто задаваемые вопросы',
    go_hashtag: '#ягуляю',
    support_belarus_call_header: 'Поддержите белорусов, протестующих {{Sunday}}',
    support_belarus_background_image: 'Фото многотысячного протеста у Стеллы в Минске',
    support_belarus_counters_title_total: 'Всемирная поддержка Беларуси',
    support_belarus_counters_title_total_walking: 'Протесты в Беларуси {{Sunday}}',
    support_button_header: 'ПОДДЕРЖИТЕ БЕЛАРУСЬ ПРЯМО СЕЙЧАС',
    support_button_desc: 'Просто нажмите кнопку "ПОДДЕРЖАТЬ ПРОТЕСТУЮЩИХ" в\u00A0воскресенье, и мы покажем народу Беларуси, что они не одиноки и что весь мир поддерживает вышедших сегодня на улицы против режима и за демократию.',
    support_button: 'ПОДДЕРЖАТЬ ПРОТЕСТУЮЩИХ',
    support_button_temp: 'ВЕРНИТЕСЬ СЮДА В ВОСКРЕСЕНЬЕ, ЧТОБЫ ПОДДЕРЖАТЬ БЕЛАРУСЬ',
    support_header: 'Почему это важно?',
    support_desc_0: 'Каждое воскресенье тысячи беларусов выходят на улицы, мирно протестуя против официальных результатов президентских выборов, фальсифицированных "последним диктатором Европы" Александром Лукашенко.',
    support_desc_1: 'Мы создали уникальную платформу "Голос", чтобы подсчитать количество людей, выходящих на протесты. Сегодня мы расширяем платформу и даём возможность всему миру высказать свою поддержку беларусам в их желании жить в правовом демократическом государстве.',
    support_desc_2: 'Мы приглашаем вас показать свою солидарность с народом Беларуси, длительное время подвергающемуся угрозам и репрессиям за мнения, расходящиеся со взглядами авторитарного правительства.',
    support_icons_header: 'ПОЧЕМУ ЛЮДИ ВЫХОДЯТ НА ПРОТЕСТЫ?',
    support_icons_title_0: 'Беларусь находится под властью "последнего диктатора Европы" А. Лукашенко уже 26 лет.',
    support_icons_title_1: 'После 1994 года, ОБСЕ признало нелегитимными каждый референдум и каждые выборы в Беларуси.',
    support_icons_title_2: 'Во время предвыборной кампании и в дни после выборов более 7 000 человек были грубо и жестоко задержаны на мирных протестах против фальсификации выборов.',
    support_icons_title_3: 'Противозаконные массовые задержания, сфабрикованные дела о преступлениях, огромные непропорциональные штрафы, превышение законных полномочий силовыми структурами (похищения, вымогательства, угрозы и жестокость, доходящая до убийства) — это сегодняшняя реальность Беларуси.',
    support_icons_desc: 'Это в корне противоречит духу свободных, прозрачных и демократических выборов и не оставляет сомнения в их противозаконности и фальсификациях. Граждане Беларуси лишены основных прав человека: права на свободу слова, права голоса, права на свободу и личную неприкосновенность. В Беларуси не существует презумпции невиновности, как не существует и независимых честных судов. Более того, милиция может препятствовать праву граждан на своевременную медицинскую помощь.',
    support_flags: 'Изображение национального бело-красно-белого флага',
    support_share_desc: 'Поделитесь этим текстом, чтобы другие поддержали Беларусь',
    support_icon_0: 'Иконка — пистолет',
    support_icon_1: 'Иконка — перечеркнутый глаз',
    support_icon_2: 'Иконка — заключенный',
    support_icon_3: 'Иконка — весы',
    support_belarus: 'ПОДДЕРЖИТЕ БЕЛАРУСЬ',
    photo_by: 'Автор фотографии: ',
    go_eng_hashtag: '#supportbelarus',
    support_button_disabled: 'СПАСИБО ЗА ВАШУ ПОДДЕРЖКУ!',
    enable_geolocation: 'Пожалуйста, включите геолокацию в своем браузере или на своем устройстве, чтобы сообщить нам свой регион.',
    walking_info: 'В воскресенье, 13\u00A0сентября, в\u00A0Беларуси долго отсутствовал интернет, что помешало многим нашим пользователям отправить команду чат-боту. Мы продлеваем подсчёт людей, участвовавших в марше, до 12.00 понедельника\u00A0— успейте прислать /ягуляю чат-боту "Голоса"!',
    walking_result_title: 'Мы гуляли',
    walking_result_supporters_title: 'Наша поддержка',
    walking_result: 'В мирных протестах в воскресенье, 13\u00A0сентября, участвовали <bold>белорусы по всей стране — из более чем 400 городов, деревень и посёлков</bold>, а поддержали их неравнодушные <bold>жители более 1400 городов и деревень из 87 стран!</bold><br /><br />На карте цветом закодировано число людей, вышедших на прогулку (чем "краснее" точка, тем больше человек гуляло в этом месте).',
    walking_by: 'в Беларуси',
    walking_eu: 'в Европе',
    walking_world: 'в мире',
  },
};
export default ru;
