import { map } from 'lodash';
import { pollingStationsApi } from '../../globals';

// MOCK DATA
// import Whitelist from './mock/whitelist.json';
// import Photos from './mock/01-010-0001_photos.json';
// import Quarantine from './mock/quarantine.json';

// REAL DATA
import Stations from './data/pollingStations.json';

// MOCK DATA
// export function getPhotos() {
//   return Promise.resolve(Photos)
//     .then((data) => map(data, (photo) => ({
//       ...photo,
//       candidateId: photo.candidateId.toLowerCase(),
//     })));
// }

// export function getWhitelist() {
//   return Promise.resolve(Whitelist);
// }

// export function getQuarantinedBulletins() {
//   return Promise.resolve(Quarantine);
// }

// export function reportBulletin() {
//   return Promise.resolve({});
// }

// REAL DATA
export function getAll() {
  return Promise.resolve(Stations);
}

export function reportBulletin(code, data) {
  return fetch(`${pollingStationsApi}/${code}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    mode: 'cors',
    credentials: 'omit',
    body: JSON.stringify(data),
  }).then((response) => response.text());
}

export function getQuarantinedBulletins(code) {
  return fetch(`${pollingStationsApi}/${code}`)
    .then((response) => response.json());
}

export function getWhitelist() {
  return fetch('/data/results/whitelist.json')
    .then((response) => response.json());
}

export function getPhotos(code) {
  return fetch(`/data/results/photos/${code}.json`)
    .then((response) => response.json())
    .then((data) => map(data, (photo) => ({
      ...photo,
      candidateId: photo.candidateId.toLowerCase(),
    })));
}
