import React, { useState, useEffect } from 'react';
import {
  Container
} from '@material-ui/core';
import { getTotalVoted } from '~/services/ResultsService';
import SupportBelarusCall from './SupportBelarusCall';
import SupportContent from './SupportContent';
import SupportBelarusFooter from './SupportBelarusFooter';
import Counters from './Counters';
import Loading from '~/components/Loading';
import Separator from '~/components/Walking/Separator';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: -theme.spacing(7.5),
  },
}));

function SupportBelarusContainer() {
  const classes = useStyles();
  const [total, setTotal] = useState(null);
  const [totalLoaded, setTotalLoaded] = useState(false);

  useEffect(() => {
    getTotalVoted().then(
      (_totalVoted) => {
        setTotal(_totalVoted);
        setTotalLoaded(true);
      },
      () => {
        setTotalLoaded(true);
      },
    );
  }, []);

  return (
    <Container
      component="main"
      maxWidth={false}
      className={classes.root}
      disableGutters
    >
      <SupportBelarusCall />
      {
        totalLoaded
          ? (
            <>
              <Counters
                totalWalking={total.TotalWalking || 0}
                SupportersAbroad={total.SupportersAbroad || 0}
              />
              <Separator />
            </>
          )
          : <Loading />
      }
      <SupportContent />
      <SupportBelarusFooter />
    </Container>
  );
}

export default SupportBelarusContainer;
