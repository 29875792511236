const getPollingStations = (state) => (
  state.pollingStations ? state.pollingStations : []
);

const getWhitelist = (state) => (
  state.whitelist
);

const getResults = (state) => (
  state.results
);

const getDetached = (state) => (
  state.detached
);

export { getPollingStations, getWhitelist, getResults, getDetached };
