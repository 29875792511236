import React from 'react';
import {
  Avatar,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { map } from 'lodash';
import clsx from "clsx";

import { useTranslation } from 'react-i18next';
import { toLocale } from '~/helpers/numberFormatter';
import BufferBar from './BufferBar';
import DeterminateBar from './DeterminateBar';
import getAll from '~/helpers/electionChoices';
import getNormalised from './BarHelper';

const useStyles = makeStyles((theme) => ({
  point: {
    marginRight: 15,
  },
  photoItem: {
    textAlign: 'right',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      textAlign: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  header: {
    padding: '50px 160px',
    textAlign: 'left',
  },
  avatar: {
    height: 40,
    width: 40,
    display: 'inline-block',
  },
  rootCell: {
    borderColor: 'rgba(26,64,90, .2)',
    [theme.breakpoints.down('lg')]: {
      padding: 8,
    },
  },
  choiceItem: {
    '&:first-of-type': {
      borderTop: 0,
    },
    '&:hover': {
      backgroundColor: '#3D9FE1',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    paddingTop: theme.spacing(1.9),
    paddingBottom: theme.spacing(1.9),
    borderTop: '1px solid rgb(26, 64, 90, 0.2)',
  },
  choice: {
    color: '#fff',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  photoVoices: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  voices: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  officialVotesItem: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  officialVotes: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    fontSize: '0.9rem',
  },
  photoVoicesDisplayLg: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  photoVoicesDisplaySm: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
  },
}));

const Table = ({
  choices,
  statistics,
  showPhotoData,
  showOfficialData,
  showIgnoreElection,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const votingChoices = getAll();
  const normalisedChoices = getNormalised(choices, showOfficialData);

  return map(votingChoices, (candidate, index) => {
    let officialNumber;

    if (showOfficialData) {
      if (choices[candidate.id]) {
        if (choices[candidate.id].officialVotes === null) {
          officialNumber = t('processing_official_numbers_table');
        } else {
          officialNumber = `${toLocale(choices[candidate.id].officialVotes)} ${t('according_to_CEC')}`;
        }
      } else {
        officialNumber = t('processing_official_numbers_table');
      }
    }

    if (!showIgnoreElection && candidate.id === 'ignore') {
      return null;
    }

    return (
      <Grid key={index} container alignItems="center" className={classes.choiceItem}>
        <Grid item sm={1} md={2} className={classes.photoItem}>
          {
            candidate.img
              ? <Avatar className={classes.avatar} src={candidate.img} />
              : null
          }
        </Grid>

        <Grid item xs={12} sm={11} md={10}>
          <Grid
            container
            alignItems="center"
          >
            <Grid item xs={12} md={3} className={classes.choice}>
              <Typography
                variant="h4"
                noWrap={false}
              >
                {t(candidate.id)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid
                container
                alignItems="flex-start"
              >
                {
                  showPhotoData && candidate.id !== 'ignore'
                    ? (
                      <>
                        <Grid item xs={12} sm={8} className={classes.photoVoicesDisplayLg}>
                          <Typography className={classes.photoVoices}>
                            {choices[candidate.id]
                              ? `${toLocale(choices[candidate.id].photoVoices)} ${t('with_photo')}`
                              : ''}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.photoVoicesDisplayLg}>
                          {/* empty */}
                        </Grid>
                      </>
                    )
                    : null
                }

                <Grid item xs={12} sm={8}>
                  {
                    showPhotoData
                      ? (
                        <BufferBar
                          value={normalisedChoices[candidate.id]
                            ? normalisedChoices[candidate.id].photoVoices
                            : 0}
                          valueBuffer={normalisedChoices[candidate.id]
                            ? normalisedChoices[candidate.id].registered
                            : 0}
                        />
                      )
                      : (
                        <DeterminateBar
                          backgroundColor="white"
                          value={normalisedChoices[candidate.id]
                            ? normalisedChoices[candidate.id].registered
                            : 0}
                        />
                      )
                  }
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography className={classes.voices} component="span">
                    {choices[candidate.id]
                      ? toLocale(choices[candidate.id].registered)
                      : '-'}
                    {' '}
                    {t('voices_plural_stats')}
                  </Typography>

                  {
                    showPhotoData && candidate.id !== 'ignore'
                      ? (
                        <>
                          &nbsp;
                          <Typography className={clsx(classes.photoVoices, classes.photoVoicesDisplaySm)} component="span">
                            {choices[candidate.id]
                              ? `/ ${toLocale(choices[candidate.id].photoVoices)} ${t('with_photo')}`
                              : ''}
                          </Typography>
                        </>
                      )
                      : null
                  }
                </Grid>

                {
                  showOfficialData
                    ? (
                      <>
                        <Grid item xs={12} sm={8} className={classes.officialVotesItem}>
                          <DeterminateBar
                            backgroundColor="black"
                            value={normalisedChoices[candidate.id]
                              ? normalisedChoices[candidate.id].officialVotes
                              : 0}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Typography className={classes.officialVotes}>
                            {officialNumber}
                          </Typography>
                        </Grid>
                      </>
                    ) : null
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });
};

Table.propTypes = {};

export default Table;
