import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  resultsLink: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  reportLink: {
    fontSize: '0.7rem',
  },
}));

export default function ResultLinks({ statistics }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (statistics.protocolPhotos.length === 0) {
    return null;
  }

  if (statistics.protocolPhotos.length === 1) {
    return (
      <Link
        className={classes.resultsLink}
        variant="body1"
        href={statistics.protocolPhotos[0]}
        color="primary"
        target="_blank"
        underline="always"
      >
        {t('PEC_results')}
      </Link>
    );
  }

  return (
    <>
      <Typography
        className={classes.resultsLink}
        variant="body1"
        color="primary"
        component="span"
        style={{ fontSize: '0.9rem' }}
      >
        {t('PEC_results').toUpperCase()}
        :
      </Typography>
      {
        map(statistics.protocolPhotos, (photo, index) => (
          <>
            &nbsp;
            <Link
              className={classes.resultsLink}
              variant="body1"
              href={photo}
              color="primary"
              target="_blank"
              underline="always"
            >
              {index + 1}
            </Link>
          </>
        ))
      }
    </>
  );
}

ResultLinks.propTypes = {
  statistics: PropTypes.objectOf(PropTypes.any).isRequired,
};
