import React from 'react';

import {
  Container,
} from '@material-ui/core';

export default function NotFound() {
  return (
    <Container component="main" maxWidth="md">
      Not Found!
    </Container>
  );
}
