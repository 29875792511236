import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faViber,
  faVk,
  faTwitter,
  faOdnoklassniki,
  faFacebookF,
  faInstagram,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';

import AppBar from './components/AppBar';
import Footer from './components/Footer';
import {
  NotFound,
  StatisticsContainer,
  PollingStationContainer,
  SupportBelarusContainer,
  WalkingContainer,
} from './views';

import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(7.5),
  },
}));

export default function App() {
  const classes = useStyles();
  const { t } = useTranslation();
  document.title = t('title');

  library.add(faViber, faVk, faTwitter, faOdnoklassniki, faFacebookF, faInstagram, faTelegramPlane);
  return (
    <div className={classes.root}>
      <Router>
        <AppBar />
        <Box className={classes.content}>
          <Switch>
            <Route path="/pollingStations/:id">
              <PollingStationContainer />
            </Route>
            <Route path="/go" exact>
              <WalkingContainer />
            </Route>
            <Route path="/supportbelarus" exact>
              <SupportBelarusContainer />
            </Route>
            <Route path="/">
              <StatisticsContainer />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Box>
        <Footer />
      </Router>
    </div>
  );
}
