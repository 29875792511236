import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveImage from '~/components/ResponsiveImage';

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
    height: '100%',
    width: '100%',
    '& img': {
      objectFit: 'cover',
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
}));

const ImageBackground = ({ altTKey, srcDefault, srcSet }) => {
  const classes = useStyles();
  return (
    <Box className={classes.image}>
      <ResponsiveImage
        altTKey={altTKey}
        srcDefault={srcDefault}
        srcSet={srcSet}
      />
    </Box>
  );
};

export default ImageBackground;

ImageBackground.propTypes = {
  altTKey: PropTypes.string.isRequired,
  srcDefault: PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  srcSet: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  })).isRequired,
};
