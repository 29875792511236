// MOCK DATA
// import Total from './mock/total.json';
// import Station from './mock/01-010-0001.json';
import { statsBucketUrl } from '../../globals';

// export function getAll() {
//   return Promise.resolve(Total);
// }

// export function get() {
//   return Promise.resolve(Station);
// }

// export function getDetached() {
//   return Promise.resolve(Station);
// }

// REAL DATA
export function getTotalVoted() {
  return fetch(statsBucketUrl)
    .then((response) => response.json())
    .then((data) => data);
}

export function getAll() {
  return fetch('/data/results/total.json')
    .then((response) => response.json())
    .then((data) => data);
}

export function get(code) {
  return fetch(`/data/results/byStation/${code}.json`)
    .then((response) => response.json())
    .then((data) => data);
}

export function getDetached() {
  return fetch('/data/results/byStation/unassigned.json')
    .then((response) => response.json())
    .then((data) => data);
}
