import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './translations/i18n';

import App from './App';
import configureStore from './redux/configureStore';
import theme from './theme/theme';
import {
  getAll as getStations,
  getWhitelist,
} from './services/PollingStationsService';
import {
  getAll as getResults,
  getDetached,
} from './services/ResultsService';
import {
  loadPollingStations,
  loadWhitelist,
  loadResults,
  loadDetached,
} from './redux/actions';

class Root extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore();
  }

  componentDidMount() {
    getStations().then((pollingStations) => {
      this.store.dispatch(loadPollingStations(pollingStations));
    });
    getWhitelist().then((whitelist) => {
      this.store.dispatch(loadWhitelist(whitelist));
    });
    getResults().then((results) => {
      this.store.dispatch(loadResults(results));
    });
    getDetached().then((detached) => {
      this.store.dispatch(loadDetached(detached));
    });
  }

  render() {
    return (
      <Provider store={this.store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
