const by = {
  translations: {
    polling_stations: 'Выбарчыя ўчасткі',
    результаты: 'Папярэднія вынікі галасавання',
    voice: 'голaс',
    with_photo: 'з фотa',
    according_to_CEC: 'па версіі УВК',
    voters: 'выбаршчыкаў',
    unique_confirmed: 'унікальных пацверджаных галасоў на платформе "Голас"',
    'фото в системе': 'правераных бюлетэняў на платформе "Голас"',
    social_media_follow: 'Далучайцеся да нас у соцсетях і мессенджерах.',
    tell_friends: 'Запрашайце сяброў і знаёмыx!',
    пользовательское: 'Пагадненне карыстальнiка',
    faq: 'Адказы на папулярныя пытанні',
    stations: 'участкаў',
    'официальная явка': 'яўка выбаршчыкаў паводле пратакола УВК',
    according_to_observers: 'паводле звестак назіральнікаў',
    honest_people_hashtag: '#мыСумленныяЛюдзі',
    we_have_voice_hashtag: '#уНасЁсцьГолас',
    all: 'Усе',
    dmitriyev: 'Дзмiтрыеў',
    kanopatskaja: 'Канапацкая',
    lukashenko: 'Лукашэнка',
    tihanovkaja: 'Цiханоўская',
    cherechen: 'Чэрачэнь',
    against: 'Супраць усiх',
    corrupted: 'Сапсаваў(ла) бюлетэнь',
    ignore: 'Не хадзіў(ла) галасаваць',
    dmitriyevAccusative: 'за Дзмiтрыева',
    kanopatskajaAccusative: 'за Канапацкую',
    lukashenkoAccusative: 'за Лукашэнка',
    tihanovkajaAccusative: 'за Цiханоўскую',
    cherechenAccusative: 'за Чэрачня',
    againstAccusative: 'супраць усiх',
    corruptedAccusative: 'Сапсаваў(ла) бюлетэнь',
    ignoreAccusative: '',
    pollingStationDetails: 'падрабязней',
    voices_plural: 'галасы',
    voices_plural_stats: 'галасоў',
    voted_for: 'Гэты голас быў аддадзены',
    back_side: 'Адваротны бок',
    honest_stations: 'Перамога кандыдата супадае ў пратаколе камісіі і дадзеных "Галасы"',
    dishonest_stations: 'Галасоў за кандыдата ў "Голасе" больш, чым у афіцыйным пратаколе (фальсіфікацыя)',
    not_enough_data: 'На участках недастаткова дадзеных для высновы аб сумленнасці працы выбарчай камісіі',
    total_num_of_voices: 'усяго галасоў',
    участок: 'Участак',
    all_stations: 'Усе ўчасткі',
    область: 'вобласць',
    title: 'Анлайн-платформа "Голас". У гэты раз ваш голас вырашае ўсё!',
    processing_monitored: 'Дадзеныя ад назіральнікаў апрацоўваюцца',
    processing_voters: 'Дадзеныя пра выбаршчыкаў апрацоўваюцца',
    processing_official_numbers: 'Дадзеныя выніковага пратакола не былі вывешаны або сфатаграфаваныя',
    processing_official_numbers_table: 'Няма дадзеных',
    protect_now: 'Абараніце ваш голас зараз!',
    more_ballots_than_official: '"Галасоў" больш, чым галасоў па версіі УВК',
    more_photos_than_official: 'Фота бюлетэняў больш, чым галасоў па версіі УВК',
    destroyed_ballot: 'Бюлетэнь сапсаваны',
    summary_results: 'Вынікі галасавання',
    detailed_report_button: 'Дэталёвая справаздача',
    results_button: 'Пратаколы УВК',
    summary_1: 'Выбары несапраўдныя, бо фальсіфікацыі былі выяўленыя на кожным трэцім выбарчым участку.',
    summary_2: 'Выніковыя дадзеныя, агучаныя ЦВК, значна разыходзяцца з афіцыйнымі дадзенымі з участковых пратаколаў.',
    summary_3: 'Аляксандр Лукашэнка не можа лічыцца легітымным прэзідэнтам Беларусі.',
    summary_4: 'Вялікая колькасць галасоў, аддадзеных за Святлану Ціханоўскую, скрадзеныя ўчастковымі камісіямі.',
    chatbot_send_photo: 'Дашліце чат-боту платформы "Голас" фатаграфію вашага бюлетэня',
    detailed_report: '<bold>Падрабязнасці</bold>',
    detailed_report_from: 'ад платформы "Голас" і ініцыятывы "Сумленныя Людзі" ––',
    here: 'тут',
    no_photos: 'Фота бюлетэняў апрацоўваюцца',
    no_photos_available: 'Фатаграфіі за дадзенага кандыдата адсутнічаюць',
    sensitive_data: 'Фота скрыта, бо на ім можа прысутнічаць пашпарт выбаршчыка або іншыя асабістыя дадзеныя. Арыгінал фота ёсць у распараджэнні платформы "Голас". Усяго на платформе 45,865 такіх фатаграфій з {{photos}} (менш за 10%)',
    photo_quarantined: 'Фота часова скрыта, бо праходзіць дадатковую апрацоўку',
    report_personal_data: 'Мы схавалі асабістыя дадзеныя (пашпарт або твар) з большай частцы фатаграфій, якія змяшчаюць іх. Калі вы ўсё ж заўважылі фота з асабістымі дадзенымі або няправільна распазнанае, паведаміце нам, націснуўшы на кнопку "Схаваць фота" унізе.',
    PEC_results: 'Пратакол',
    send_results_photo: 'Даслаць фота пратакола',
    station_code: 'Код участка',
    photos_disclaimer: 'Фатаграфіі пратаколаў мы атрымалі ад незалежных назіральнікаў ініцыятыў "Сумленныя людзі", "Зубр", а таксама з адкрытых крыніц. Мы ацэньваем дакладнасць дадзеных у 98%. Калі вы выявіце недакладнасць ці памылку, паведаміце нам аб гэтым з дапамогай',
    this_form: 'гэтай формы',
    site_title: 'Вынікі платформы "Голас": знайдзі свой участак!',
    site_description: 'Дапаможам абараніць ваш выбар і пакажам вынікі, падлічаныя не камісіямі, а выбаршчыкамі. Заходзьце ў Viber, Telegram, раскажыце пра свой выбар!',
    error_notify: 'Паведаміць пра памылку',
    notify: 'Схаваць фота',
    notify_personal_data: 'Змяшчае асабістыя дадзеныя',
    notify_incorrect_data: 'Няправільны кандыдат',
    notify_other: 'Іншая праблема',
    problem: 'Праблема',
    state_problem: 'Апішыце праблему',
    report_manually: 'Калі па нейкай прычыне форма вышэй не працуе, адпраўце памылку ўручную з дапамогай',
    missing_photo1: 'Не можаце знайсці сваю фатаграфію? ',
    missing_photo2: 'На платформе "Голас" змяшчаецца ',
    missing_photo3: '{{registered}} галасоў',
    missing_photo4: ', не прывязаных да ўчастку, з іх {{photoVoices}} –– з фота.',
    // walking_call_header: 'Гуляйце ў добрай кампаніі',
    walking_call_header: '20 ВЕРАСНЯ — ДЗЕНЬ САЛІДАРНАСЦІ З БЕЛАРУССЮ',
    solid_day_announce_big: '<bold>20\u00A0верасня</bold> "Голас" далучаецца да глабальнага Дню салідарнасці з Беларуссю. Далучайцеся да акцыі падтрымкі нашай краіны, націснуўшы кнопку "Падтрымаць беларусаў".',
    solid_day_announce_small: 'Мы прыпынілі каманду /ягуляю ў чат-робатах на час правядзення апытання ў падтрымку Каардынацыйнай Рады і Дня салідарнасці.<br /><br />У нядзелю, 20 верасня, і беларусы, і замежнікі могуць падтрымаць пратэсты ў Беларусі на нашай спецыяльнай старонцы.',
    button_support_belarus: 'ПАДТРЫМАЦЬ БЕЛАРУСАЎ',
    walking_call_button: 'Як гэта працуе?',
    us_already: 'Зарэгістраваліся ў "Голасе"',
    walking_today: '{{Walk}} па вуліцы ў Беларусі {{Sunday}}',
    walking_home: '{{Walk}} дома ў Беларусі {{Sunday}}',
    image_author: 'аўтар ілюстрацыі:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Крок 1: Зарэгіструйцеся ў\u00A0"Голасе"',
    walking_title_step_2: 'Крок 2: Паведаміце чат-боту, што вы гуляеце',
    walking_title_step_3: 'Крок 3: Падзяліцеся прагулкай з\u00A0сябрамі',
    walking_activate_bot: 'Актывуйце бота перад прагулкай',
    walking_activate_bot_desc_1: 'Перад выхадам на прагулку актывуйце чат-бота: адпраўце яму каманду <bold>/ягуляю</bold>. Такім чынам вы паведаміце "Голасу", што у гэты канкрэтны дзень вы далучыліся да пратэста. Хочаце пайсці гуляць, але не можаце – усё роўна адпраўляйце каманду.',
    walking_activate_bot_desc_2: 'Удзел у законных маштабных маршах, лакальныя мітынгі, ланцужкі салідарнасці, любыя ініцыятывы, да нават прагулка ўва двары і гутарка з суседзямі – форма пратэста не мае значэння. Галоўнае, што вы актыўны і наш чат-бот аб гэтым ведае. Платформе не патрэбны вашыя персанальныя дадзеныя – мы хочам толькі падлічыць удзельнікаў пратэста.',
    walking_activate_bot_desc_3: 'Учыняем падлік кожную нядзелю і ў спецыяльныя дні, пра якія мы будзем паведамляць асобна.',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Выкарыстайце тэг #ягуляю',
    walking_use_hashtag_desc_1: 'Чым нас больш – тым грамчэй наш голас. Уладам будзе больш складана і надалей рабіць выгляд, што нас не чутна. Фатаграфуйцеся падчас сваіх прагулак, паказвайце ў сацсетках, стаўце хэштэг <bold>#ягуляю</bold>.',
    walking_use_hashtag_desc_2: 'Раскажыце аб праекце усім знаёмым. Наш лічыльнік – доказ нашай праўды і нашай перамогі.',
    walking_how_title: 'Як гэта працуе',
    walking_how_desc_1: 'Новы спецпраект "Голаса" – <bold>#ягуляю</bold>. Цяпер мы хочам падлічыць беларусаў, якія выходзяць на пратэсты, адстойваюць сваю свабоду і новыя сумленныя выбары. Мы хочам паказаць усяму свету рэальную карціну барацьбы за справядлівасць.',
    walking_how_desc_2: '<bold>#ягуляю</bold> будзе лічыць усіх: хто законна гуляе ў Мінску і буйных гарадах, хто гуляе ў далёкіх кутках Беларусі ў падтрымку ўдзельнікаў пратэстаў, хто аб\'ектыўна не можа гуляць, але таксама супраць беззаконня.',
    walking_how_desc_3: 'Гэта ананімна і бяспечна. Далучыцца можа кожны. Нават той, хто хоча падтрымаць пратэст, але пакуль не вырашыўся зрабіць гэта адкрыта.',
    walking_how_desc_4: 'У "Голасе" ужо больш за <bold>1 200 000</bold> чалавек. І зараз мы ўзнаўляем рэгістрацыю на платформе.',
    walking_how_desc_5: 'Ўсталявалі бота? Падзяліцеся нашай ідэей з сябрамі!',
    walking_how_desc_6: '<bold>Калі вы ўжо зарэгістраваліся ў чат-боце, пераходзьце да наступнага кроку.</bold>',
    walking_how_desc_7: '<bold>Калі рэгіструецеся ўпершыню – націсніце на кнопку ўлюбёнага мэсэнжэра і кіруйцеся інструкцыямі ў чат-боце. На першым кроку пацвердзіце, што адправілі нумар тэлефона (ён будзе надзейна зашыфраваны).</bold>',
    no_messenger: 'Калі ў вас няма мэсэнжэра',
    faq_answers: 'Адказы на частыя пытанні',
    go_hashtag: '#ягуляю',
    walking_info: 'У нядзелю, 13\u00A0верасня, у\u00A0Беларусі доўга адсутнічаў інтэрнэт, што перашкодзіла шматлікім нашым карыстальнікам адправіць каманду чат-боту. Мы працягваем падлік людзей, якія ўдзельнічалі ў маршы, да 12.00 панядзелка\u00A0– паспейце даслаць /ягуляю чат-боту "Голаса".',
    walking_result_title: 'Мы гулялі',
    walking_result: 'У мірных акцыях пратэсту ў нядзелю, 13\u00A0верасня, прынялі ўдзел <bold>беларусы па ўсёй краіне — з больш чым 400 гарадоў, вёсак і пасёлкаў</bold>, а падтрымалі іх неабыякавыя <bold> жыхары з больш чым 1400 гарадоў і вёсак з 87 краін! </bold> <br /> <br /> На карце адлюстравана закадаваная колькасць людзей, якія выйшлі гуляць (чым чырванейшы колер, тым больш людзей было там).',
    walking_by: 'у Беларусі',
    walking_eu: 'у Еўропе',
    walking_world: 'у свеце',
  },
};
export default by;
