import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Link,
  Typography,
  IconButton, useMediaQuery,
} from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  capitalize,
  filter,
  indexOf,
  isEmpty,
} from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

import {
  getPhotos,
  reportBulletin,
  getQuarantinedBulletins,
} from '~/services/PollingStationsService';
import { getResults } from '~/redux/selectors';
import { toLocale } from '~/helpers/numberFormatter';
import BulletinInfo from './BulletinInfo';
import getAll from '~/helpers/electionChoices';
import Loading from '~/components/Loading';
import NoBulletins from './NoBulletins';
import Photos from '~/assets/images/photos.svg';
import Select from '~/components/Select.jsx';
import ReportPersonalData from './ReportPersonalData';
import BulletinImage from './BulletinImage';

const useStyles = makeStyles((theme) => ({
  paddingSM: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(5),
    },
  },
  titleItem: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  imageNav: {
    position: 'absolute',
    zIndex: 4,
    top: '50%',
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  leftNav: {
    marginLeft: theme.spacing(1),
    paddingLeft: '23px',
    '& > span': {
      width: '24px',
    },
  },
  rightNav: {
    marginRight: theme.spacing(1),
    right: 0,
  },
  imageContainer: {
    display: 'flex',
    padding: '0 100px',
    height: '600px',
  },
}));

function BulletinsContainer({ pollingStationId, results }) {
  const { t } = useTranslation();
  const [bulletinsLoaded, setBulletinsLoaded] = useState(false);
  const [bulletins, setBulletins] = useState(null);
  const [candidateId, setCandidateId] = useState('all');
  const [bulletin, setBulletin] = useState(null);
  const [noPhotos, setNoPhotos] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quarantinedBulletins, setQuarantinedBulletins] = useState(null);
  const [quarantinedBulletinsLoaded, setQuarantinedBulletinsLoaded] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [reportError, setReportError] = React.useState(null);

  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width: 767px)');

  const getLeftButton = (onClick, disabled) => (
    <IconButton
      className={clsx(classes.leftNav, classes.imageNav)}
      aria-label="left"
      disabled={disabled}
      onClick={onClick}
      color="primary"
    >
      <ArrowBackIosIcon fontSize="large" />
    </IconButton>
  );

  const getRightButton = (onClick, disabled) => (
    <IconButton
      className={clsx(classes.rightNav, classes.imageNav)}
      disabled={disabled}
      onClick={onClick}
      aria-label="right"
      color="primary"
    >
      <ArrowForwardIosIcon fontSize="large" />
    </IconButton>
  );

  const getQuarantinedPhotos = (_pollingStationId) => getQuarantinedBulletins(_pollingStationId)
    .then(
      (_quarantinedBulletins) => {
        setQuarantinedBulletins(_quarantinedBulletins);
        setQuarantinedBulletinsLoaded(true);
      },
      () => {
        setQuarantinedBulletinsLoaded(false);
      },
    );

  useEffect(() => {
    getQuarantinedPhotos(pollingStationId);
  }, [pollingStationId]);

  useEffect(() => {
    getPhotos(pollingStationId).then(
      (_bulletins) => {
        setBulletin(_bulletins.length ? _bulletins[0] : null);
        setCurrentIndex(0);
        setBulletins(_bulletins);
        setBulletinsLoaded(true);
      },
      () => {
        setNoPhotos(true);
        setBulletinsLoaded(false);
      },
    );
  }, [pollingStationId]);

  async function handleReportBulletin(data) {
    try {
      const response = await reportBulletin(pollingStationId, data);

      if (!isEmpty(response)) {
        throw response;
      }

      getQuarantinedPhotos(pollingStationId);
      setReportError(null);
    } catch (e) {
      setReportError(e);
    }
  }

  const showBackSide = (secondaryImage) => {
    if (secondaryImage && secondaryImage !== 'none') {
      const imageFileName = secondaryImage.slice(secondaryImage.lastIndexOf('/') + 1);

      return indexOf(quarantinedBulletins, imageFileName) === -1;
    }

    return false;
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const getGalleryItems = () => {
    const galleryItems = [];

    for (let bulletinId = 0; bulletinId < bulletins.length; bulletinId += 1) {
      const {
        candidateId: _candidateId,
        primaryImage,
        secondaryImage,
        hasPersonalData,
      } = bulletins[bulletinId];

      if (candidateId === 'all' || _candidateId === candidateId) {
        const item = {
          bulletinId,
        };

        if (hasPersonalData === true) {
          item.description = t('sensitive_data', { photos: toLocale(results.photoVoices) });
        }

        if (primaryImage && primaryImage !== 'none') {
          const imageFileName = primaryImage.slice(primaryImage.lastIndexOf('/') + 1);
          if (indexOf(quarantinedBulletins, imageFileName) !== -1) {
            item.original = Photos;
            item.description = t('photo_quarantined');
            item.isQuarantined = true;
          } else {
            item.original = primaryImage;

            if (showBackSide(secondaryImage)) {
              item.backSide = secondaryImage;
            }
          }
        } else {
          item.original = Photos;
        }

        galleryItems.push(item);
      }
    }

    return galleryItems;
  };

  let galleryItems = [];
  if (bulletinsLoaded && quarantinedBulletinsLoaded) {
    galleryItems = getGalleryItems(candidateId, bulletins, quarantinedBulletins);
  }

  const onSlide = (index) => {
    const galleryImage = galleryItems[index];
    const { bulletinId } = galleryImage;

    setCurrentIndex(index);
    setBulletin(bulletins[bulletinId]);
  };

  const renderBulletin = (item) => (
    <div className={clsx(classes.imageContainer)}>
      <BulletinImage
        link={item.original}
        className={!item.backSide ? 'image-gallery-image' : ''}
        isClickable={!item.isQuarantined}
      />

      {
        !item.isQuarantined
        && item.backSide
        && <BulletinImage link={item.backSide} isClickable />
      }

      {
        item.description
        && <span className="image-gallery-description">{item.description}</span>
      }
    </div>
  );
  const candidates = filter(getAll(), (candidate) => candidate.id !== 'ignore'); // remove 'не пойду на выборы'
  const defaultCandidate = {
    id: 'all',
    content: t('all'),
  };
  const setCandidate = (_candidateId) => {
    const currentBulletin = _candidateId !== 'all' ? bulletins.find((_) => (
      _.candidateId === _candidateId
    )) : bulletins[0];

    setCandidateId(_candidateId);
    setBulletin(currentBulletin);
    setCurrentIndex(0);
  };

  const isReportFormValid = () => recaptchaToken !== null;

  const handleCloseReportError = () => {
    setReportError(null);
  };

  return noPhotos
    ? <NoBulletins />
    : (
      <>
        <Grid
          container
        >
          <Grid item xs={12} sm={9}>
            <Grid
              container
            >
              <Grid item md={2}>
                {/* Empty */}
              </Grid>

              <Grid item xs={12} sm={10} className={clsx(classes.titleItem, classes.paddingSM)}>
                <Typography
                  component="h3"
                  variant="h3"
                  style={{ display: 'inline', marginRight: '2rem' }}
                >
                  {capitalize(t('voices_plural'))}
                </Typography>

                <Select
                  display="inline"
                  defaultValue={defaultCandidate.id}
                  items={[defaultCandidate, ...candidates].map((x) => ({
                    id: x.id,
                    content: x.content || t(x.id),
                  }))}
                  onChange={setCandidate}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        {
          bulletin
            ? (
              <Grid
                container
              >
                <Grid item xs={12} sm={9}>
                  <Grid
                    container
                  >
                    <Grid item md={2}>
                      {/* Empty */}
                    </Grid>

                    <Grid item xs={12} sm={10} className={classes.paddingSM}>
                      <BulletinInfo
                        bulletin={bulletin}
                        quarantinedBulletins={quarantinedBulletins}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
            : null
        }

        {
          // eslint-disable-next-line no-nested-ternary
          bulletinsLoaded
            ? (
              galleryItems.length
                ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={9}>
                      <ImageGallery
                        lazyLoad
                        additionalClass="bulletin-gallery"
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        items={galleryItems}
                        onSlide={onSlide}
                        showIndex
                        startIndex={currentIndex}
                        renderRightNav={getRightButton}
                        renderLeftNav={getLeftButton}
                        renderItem={!isMobile ? renderBulletin : undefined}
                      />
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid
                    container
                  >
                    <Grid item xs={12} sm={9}>
                      <Grid
                        container
                      >
                        <Grid item md={2}>
                          {/* Empty */}
                        </Grid>

                        <Grid item xs={12} sm={10} className={classes.paddingSM}>
                          <Typography
                            component="span"
                            variant="h5"
                          >
                            {t('no_photos_available')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
            )
            : <Loading />
        }

        {
          bulletin
            ? (
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={9} className={classes.paddingSM}>
                  <ReportPersonalData
                    bulletin={bulletin}
                    onReport={handleReportBulletin}
                    onRecaptcaChange={handleRecaptchaChange}
                    onErrorClose={handleCloseReportError}
                    valid={isReportFormValid()}
                    error={reportError}
                  />
                </Grid>
              </Grid>
            )
            : null
        }
      </>
    );
}

BulletinsContainer.propTypes = {
  pollingStationId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  results: getResults(state),
});

export default connect(mapStateToProps)(BulletinsContainer);
