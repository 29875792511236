import React from 'react';

import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
  },
}));

export default function Separator() {
  const classes = useStyles();
  return <Box className={classes.root} />;
}
