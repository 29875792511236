import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    width: '90%',
  },
  buffer: {
    backgroundColor: 'transparent',
  },
  bar2Buffer: {
    backgroundColor: '#A5D5F5',
  },
  barColorPrimary: {
    backgroundColor: 'white',
  },
  dashed: {
    background: 'transparent',
    animation: 'unset',
  },
}));

const BufferBar = (props) => {
  const classes = useStyles();

  return (
    <LinearProgress
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      color="primary"
      variant="buffer"
      classes={{
        bar2Buffer: classes.buffer2,
        barColorPrimary: classes.barColorPrimary,
        buffer: classes.buffer,
        dashed: classes.dashed,
        root: classes.root,
      }}
    />
  );
};

BufferBar.propTypes = {
  value: PropTypes.number.isRequired,
  valueBuffer: PropTypes.number.isRequired,
};

export default BufferBar;
