import React from 'react';
import PropTypes from 'prop-types';
import Select from '~/components/Select.jsx';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const languages = [{
  id: 'by',
  content: 'БЕЛ',
}, {
  id: 'ru',
  content: 'РУС',
}, {
  id: 'en',
  content: 'ENG',
}];

const useStyles = makeStyles({
  singleLang: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    display: 'inline',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    paddingBottom: '7px',
  },
});

const LanguageSelect = ({ langs, onSelect, selected }) => {
  const langFilter = languages.filter((el) => langs.includes(el.id));
  const langMissed = !langs.includes(selected);
  const classes = useStyles();

  if (langMissed) {
    onSelect(langs[0]);
  }

  if (!langs.length) return <></>;

  if (langs.length === 1) {
    return (
      <Typography
        className={classes.singleLang}
          >
          {langFilter[0].content}
      </Typography>
    );
  }

  return (
    <Select
      display="inline"
      items={langFilter}
      onChange={onSelect}
      defaultValue={langMissed ? langs[0] : selected}
    />
  );
};
LanguageSelect.defaultProps = {
  langs: ['by', 'ru', 'en'],
};
LanguageSelect.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default LanguageSelect;
