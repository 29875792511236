import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  notLast: {
    borderBottom: '1px solid #000',
  },
  root: {
    background: '#252525',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
    color: 'white',
  },
  image: {
    textAlign: 'left',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        width: '25px',
      },
    },
  },
}));

export default function AsideItem({
  className,
  last,
  image,
  text,
  xs = 12,
  sm = 6,
  md = 12
}) {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      className={clsx(className, classes.root, { [classes.notLast]: !last })}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item sm={12} md={2} className={classes.image}>
          {image}
        </Grid>
        <Grid item xs={10} sm={12} md={9}>
          {text}
        </Grid>
      </Grid>
    </Grid>
  );
}
