import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const ResponsiveImage = ({ altTKey, srcDefault, srcSet }) => {
  const { t } = useTranslation();

  const src = srcDefault.url;
  let srcSetOutput = '';
  let sizes = '';

  srcSet.forEach((el) => {
    srcSetOutput += `${el.url} ${el.width}w, `;
    sizes += `(max-width: ${el.width}px) ${el.width - 40}px, `;
  });

  srcSetOutput += `${srcDefault.url} ${srcDefault.width}w, `;
  sizes += `${srcDefault.width - 40}px`;

  return (
    <img
      srcSet={srcSetOutput}
      sizes={sizes}
      src={src}
      alt={t(altTKey)}
    />
  );
};

export default ResponsiveImage;

ResponsiveImage.propTypes = {
  altTKey: PropTypes.string.isRequired,
  srcDefault: PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  srcSet: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  })).isRequired,
};
