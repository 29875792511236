import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Link,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { map } from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

import Voice from '~/assets/images/voice.svg';
import Observer from '~/assets/images/nabludatel.svg';
import OfficialTurnout from '~/assets/images/official-turnout.svg';
import {
  toLocale,
} from '~/helpers/numberFormatter';
import AsideItem from '~/components/AsideItem';
import ResultLinks from '~/components/ResultLinks';
import ReportProblemLink from '~/components/ReportProblemLink';


const useStyles = makeStyles((theme) => ({
}));

export default function PollingStationStatistics({ statistics }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const resultsLinks = (
    <Box mt={2}>
      <ResultLinks statistics={statistics} />

      <Box mt={2}>
        <ReportProblemLink
          color="inherit"
          displayIcon
        />
      </Box>
    </Box>
  );

  return (
    <Grid item xs={12} md={3}>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-end"
      >
        <AsideItem
          image={
            <img width={35} src={Voice} alt="Уникальных подтвержденных голосов на платформе Голос" />
          }
          text={(
            <>
              <Typography
                component="span"
                variant="h3"
              >
                {toLocale(statistics.registered)}
              </Typography>
              <br />
              <Typography
                component="span"
              >
                {t('unique_confirmed')}
              </Typography>
            </>
          )}
        />

        <AsideItem
          image={
            <img width={35} src={OfficialTurnout} alt="Официальная явка" />
          }
          text={
            statistics.officialVotes === null
              ? (
                <>
                  <Typography
                    component="span"
                    noWrap={false}
                  >
                    {t('processing_official_numbers')}
                  </Typography>

                  {resultsLinks}
                </>
              ) : (
                <>
                  <Typography
                    component="span"
                    variant="h3"
                  >
                    {toLocale(statistics.officialVotes)}
                  </Typography>

                  <br />
                  <Typography
                    component="span"
                  >
                    {t('официальная явка')}
                  </Typography>

                  {resultsLinks}
                </>
              )
          }
        />

        <AsideItem
          last
          image={
            <img width={35} src={Observer} alt="По данным наблюдателей" />
          }
          text={
            statistics.monitoredVotes === null
              ? (
                <Typography
                  component="span"
                  noWrap={false}
                >
                  {t('processing_monitored')}
                </Typography>
              ) : (
                <>
                  <Typography
                    component="span"
                    variant="h3"
                  >
                    {toLocale(statistics.monitoredVotes)}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    noWrap={false}
                  >
                    {t('according_to_observers')}
                  </Typography>
                </>
              )
          }
        />
      </Grid>
    </Grid>
  );
}

PollingStationStatistics.propTypes = {
  statistics: PropTypes.objectOf({
    registered: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    officialVotes: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    monitoredVotes: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
};
