export const formatFixed = (number, precision) => parseFloat(number.toFixed(precision)).toString();

export const formatAmount = (number, precision = 1) => {
  if (!Number.isFinite(number)) {
    return number;
  }
  let amount = Math.abs(number);
  const postfixes = ['', 'k', 'm', 'b', 't'];
  // eslint-disable-next-line no-restricted-syntax
  for (const postfix of postfixes) {
    if (Math.round(amount) < 1e3) {
      const formattedAmount = formatFixed(amount, precision) + postfix;
      return number >= 0 ? formattedAmount : `-${formattedAmount}`;
    }
    amount /= 1e3;
  }
  // Infinity otherwise
  return '~';
};

export const formatPlural = (count, options, fallback) => {
  // subset of ICU format here, see https://formatjs.io/docs/icu-syntax/#plural-format
  const {
    zero, one, few, other,
  } = options;
  const exactMatch = options[`=${count}`];
  if (exactMatch) {
    return exactMatch;
  }
  if (count === 0 && zero) {
    return zero;
  }
  if (count === 1 && one) {
    return one;
  }
  if (few && count >= 2 && count <= 4) {
    return few;
  }
  return other || fallback;
};

export const formatPluralWithValue = (count, options, fallback) => `${count} ${formatPlural(count, options, fallback)}`;
