/* eslint-disable arrow-body-style */
import {
  values,
  map,
  max,
  min,
  forEach,
} from 'lodash';

const getNormalised = (results, showOfficialData) => {
  let data = {};

  if (showOfficialData) {
    data = results;
  } else {
    forEach(results, (choice, key) => {
      data[key] = {
        registered: choice.registered,
        photoVoices: choice.photoVoices,
      };
    });
  }

  const globalMax = max(map(data, (choice) => max(values(choice))));
  const globalMin = min(map(data, (choice) => min(values(choice))));
  const normalised = {};

  forEach(data, (choice, id) => {
    const normalise = (val) => ((val - globalMin) * 100) / (globalMax - globalMin);

    normalised[id] = {
      registered: normalise(choice.registered),
      photoVoices: normalise(choice.photoVoices),
    };

    if (showOfficialData) {
      normalised[id].officialVotes = normalise(choice.officialVotes);
    }
  });
  return normalised;
};

export default getNormalised;
