import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({
  root: {
    height: 20,
    width: '90%',
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: props => props.backgroundColor,
  },
  dashed: {
    background: 'transparent',
    animation: 'unset',
  },
  determined: {
    backgroundColor: 'transparent',
  },
}));

const DeterminateBar = (props) => {
  const classes = useStyles(props);

  return (
    <LinearProgress
      value={props.value}
      variant="determinate"
      color="primary"
      classes={{
        barColorPrimary: classes.barColorPrimary,
        colorPrimary: classes.colorPrimary,
        root: classes.root,
      }}
    />
  );
};

DeterminateBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default DeterminateBar;
