import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import Link from '~/components/Link';
import { toLocale } from '~/helpers/numberFormatter';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingTop: theme.spacing(5),
  },
  empty: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  detailedReport: {
    marginTop: theme.spacing(5),
    background: '#f0f0f0',
  },
  image: {
    marginTop: theme.spacing(0.6),
    marginRight: theme.spacing(2.5),
  },
  titleItem: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    marginBottom: theme.spacing(5),
  },
}));

export default function MissingPhoto({ detached }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={9} className={classes.detailedReport}>
        <Grid
          container
          alignItems="flex-start"
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={10} className={classes.titleItem}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item>
                <FindInPageIcon
                  color="primary"
                  fontSize="large"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography
                  component="span"
                  variant="body1"
                >
                  {t('missing_photo1')}
                  {t('missing_photo2')}
                </Typography>
                <Link
                  color="primary"
                  variant="body1"
                  underline="always"
                  style={{ fontWeight: 'bold' }}
                  to="/pollingStations/unassigned"
                >
                  {t('missing_photo3', { registered: toLocale(detached.registered) })}
                </Link>
                <Typography
                  component="span"
                  variant="body1"
                >
                  {t('missing_photo4', { photoVoices: toLocale(detached.photoVoices) })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
