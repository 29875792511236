import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';
import theme from '../../theme/theme';
const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'start',
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: theme.spacing(3.5),
    [theme.breakpoints.up('sm')]: {
      justifyContent: (props) => props.justifyContent,
    },
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  number: {
    alignItems: 'center',
    color: (props) => props.color,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontWeight: '600',
    '&:not(:first-child)': {
      borderLeft: '0px',
    },
    '&:nth-last-child(3n):not(:first-child)': {
      borderLeft: (props) => `4px solid ${props.color}`,
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('xs')]: {
      border: (props) => `2px solid ${props.color}`,
      fontSize: '1.9rem',
      lineHeight: '1.9rem',
      padding: '1.2rem 0.1rem',
      width: '42px',
      '&:nth-last-child(3n):not(:first-child)': {
        borderLeft: (props) => `2px solid ${props.color}`,
        marginLeft: theme.spacing(1),
      },
    },
    [theme.breakpoints.up('sm')]: {
      border: (props) => `2px solid ${props.color}`,
      fontSize: '2.4rem',
      lineHeight: '2.4rem',
      padding: '1.4rem 0.1rem',
      width: '50px',
      '&:nth-last-child(3n):not(:first-child)': {
        borderLeft: (props) => `2px solid ${props.color}`,
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      lineHeight: '3rem',
      padding: '1.5rem 0',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '4.7rem',
      lineHeight: '4.7rem',
      padding: '1.7rem 0',
      width: '70px',
    },
  },
}));

const Counter = ({ total, type, position }) => {
  const classes = useStyles({
    color: type === 'dark' ? theme.palette.text.primary : type === 'light' ? theme.palette.white : theme.palette.blue,
    justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
  });
  return (
    <Typography
      component="div"
      className={classes.root}
    >
      {total.toString().split('').map((num, i) => (
        <Typography
          component="span"
          className={classes.number}
          key={`cn_${i}`}
        >
          {num}
        </Typography>
      ))}
    </Typography>
  );
};
Counter.defaultProps = {
  type: 'dark',
  position: 'left'
};
Counter.propTypes = {
  type: PropTypes.oneOf(['light', 'dark', 'blue']),
  total: PropTypes.number.isRequired,
  position: PropTypes.oneOf(['left', 'right'])

};

export default Counter;
