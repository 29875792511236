import React from 'react';

import {
  Container, Typography, Box, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HonestLogo from '~/assets/images/honest-people.svg';
import {
  faqUrl, termsUrl,
} from '../../globals';

const useStyles = makeStyles((theme) => ({
  honestLink: {
    marginTop: -25,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      margin: '20px 0 30px 0',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
  },
  footer: {
    marginTop: 'auto',
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  terms: {
    textAlign: 'center',
    background: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: 12,
    paddingBottom: 14,
  },
  hashTag: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
    },
  },
  termsText: {
    borderBottom: '1px solid #fff',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
  fa: {
    verticalAlign: '-0.2em'
  },
  flexContainer: {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(5),
    },
  },
  socialsWrap: {
    textAlign: 'center',
  }
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container justify="space-between" alignItems="center" className={classes.flexContainer}>
          <Grid item className={classes.honestLink}>
            <a href="https://honest-people.by" target="_blank" rel="noreferrer">
              <img width="163" height="65" src={HonestLogo} alt="Честные люди" />
            </a>
          </Grid>
          <Grid item>
            <Typography align="left">
              {t('social_media_follow')}
              <br />
              {t('tell_friends')}
            </Typography>
          </Grid>
          <Grid item>
            <Box mt={2} mb={3} className={classes.socialsWrap}>
              <a href="https://vk.com/vote.belarus" className={clsx(classes.link, 'vk social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'vk']} color="white" className={classes.fa} />
              </a>
              {/* <a href="https://www.facebook.com/vote.belarus/?modal=admin_todo_tour" className={clsx(classes.link, 'facebook social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} color="white" className={classes.fa} />
              </a> */}
              <a href="https://www.instagram.com/vote.belarus/" className={clsx(classes.link, 'instagram social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'instagram']} color="white" className={classes.fa} />
              </a>
              <a href="https://twitter.com/votebelarus" className={clsx(classes.link, 'twitter social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'twitter']} color="white" className={classes.fa} />
              </a>
              <a href="https://ok.ru/group/58439959183470" className={clsx(classes.link, 'odnoklassniki social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'odnoklassniki']} color="white" className={classes.fa} />
              </a>
              <a href="https://bit.ly/golosby_join" className={clsx(classes.link, 'telegram social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'telegram-plane']} color="white" className={classes.fa} />
              </a>
              <a href="https://invite.viber.com/?g2=AQBZ9eVwoKmWt0vfMTIOw26q7lxbECkQmqGc7Cu1sG7XqSPkWMU8H2Rd%2F%2BF%2BiKcL" className={clsx(classes.link, 'viber social')} rel="noreferrer" target="_blank">
                <FontAwesomeIcon icon={['fab', 'viber']} color="white" className={classes.fa} />
              </a>
            </Box>
          </Grid>
          <Grid item className={classes.hashTag}>
            <a className={classes.link} href="https://www.instagram.com/explore/tags/мычестныелюди" rel="noreferrer" target="_blank">
              {t('honest_people_hashtag')}
            </a>
            <br />
            <a className={classes.link} href="https://www.instagram.com/explore/tags/унасестьголос" rel="noreferrer" target="_blank">
              {t('we_have_voice_hashtag')}
            </a>
            <br />
            <a className={classes.link} href="https://www.instagram.com/explore/tags/ягуляю" rel="noreferrer" target="_blank">
              {t('go_hashtag')}
            </a>
            <br />
            <a className={classes.link} href="https://www.instagram.com/explore/tags/supportbelarus" rel="noreferrer" target="_blank">
              {t('go_eng_hashtag')}
            </a>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.terms}>
        <a className={classes.link} href={termsUrl} target="_blank" rel="noreferrer">
          <Typography component="span" className={classes.termsText}>{t('пользовательское')}</Typography>
        </a>
        <a className={classes.link} href={faqUrl} target="_blank" rel="noreferrer">
          <Typography component="span" className={classes.termsText}>{t('faq')}</Typography>
        </a>
      </Box>
    </footer>
  );
}
