import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Map.css';
import { map, find, forEach } from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getPollingStations, getWhitelist } from '~/redux/selectors';

import { all, setPollingStations } from './golos';

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    margin: '0',
    [theme.breakpoints.up('xs')]: {
      height: '500px',
      padding: '0 12px',
    },
    [theme.breakpoints.up('md')]: {
      height: '669px',
      padding: '0',
    },
  },
}));

const Map = ({ onPollingStationClicked, pollingStations, whitelist }) => {
  const classes = useStyles();

  useEffect(() => {
    all(onPollingStationClicked);
  }, [onPollingStationClicked]);

  useEffect(() => {
    if (pollingStations && whitelist) {
      forEach(whitelist.forgery, (id) => {
        const forged = find(pollingStations, ['code', id]);
        forged.forged = true;
      });
      forEach(whitelist.truth, (id) => {
        const truthful = find(pollingStations, ['code', id]);
        truthful.forged = false;
      });

      setPollingStations(pollingStations);
    }
  }, [pollingStations, whitelist]);
  return (<div id="map" className={classes.map} />);
};

Map.propTypes = {
  onPollingStationClicked: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pollingStations: getPollingStations(state),
  whitelist: getWhitelist(state),
});

export default connect(mapStateToProps)(Map);
