import { v4 as uuidv4 } from 'uuid';
import { abroadCheckinApi, cookieAbroadSupport } from '../../globals';

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop().split(';').shift();

  return false;
}

const setCookieForDay = (name, value) => {
  let date = new Date();
  date = new Date(date.setDate(date.getDate() + 1));
  const expires = date.toUTCString();

  document.cookie = `${name}=${value}; expires=${expires}"; path=/`;
};
export function abroadCheckin(location, callback) {
  const key = cookieAbroadSupport;
  const userId = uuidv4();

  return fetch(abroadCheckinApi, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-user': userId,
    },
    body: JSON.stringify(location),
  })
    .then((data) => {
      console.log(data);
      if (data.status === 200) {
        setCookieForDay(key, userId);
        callback();
      }
    })
    .catch((e) => {
      console.log('error');
      console.log(e);
    });
}
