import { combineReducers } from 'redux';

import detached from './detached';
import pollingStations from './pollingStations';
import results from './results';
import whitelist from './whitelist';

export default combineReducers({
  detached,
  pollingStations,
  results,
  whitelist,
});
