import React from 'react';
import {
  Link, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
    fontSize: '0.7rem',
    lineHeight: '1rem',
  },
}));

export default function ReportProblemLink({ color = 'textPrimary', displayIcon = false }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const reportLink = (
    <Link
      className={classes.root}
      color={color}
      variant="body1"
      underline="always"
      href="https://forms.gle/XB6gzx3jw888HSZm8"
      rel="noreferrer"
      target="_blank"
    >
      {t('error_notify')}
    </Link>
  );

  return displayIcon
    ? (
      <Grid
        container
      >
        <Grid item>
          <ReportOutlinedIcon
            fontSize="small"
            color="inherit"
          />
        </Grid>
        <Grid item>
          {reportLink}
        </Grid>
      </Grid>
    )
    : reportLink;
}

ReportProblemLink.propTypes = {
  color: PropTypes.string,
  displayIcon: PropTypes.bool,
};

ReportProblemLink.defaultProps = {
  color: 'textPrimary',
  displayIcon: false,
};
