import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Photos from '~/assets/images/photos.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(4),
    marginTop: theme.spacing(5),
    background: '#f0f0f0',
  },
  text: {
    display: 'inline-block',
    marginTop: '19px',
  },
}));

export default function NoBulletins() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      textAlign="center"
      spacing={2}
    >
      <Grid item>
        <img width={50} src={Photos} alt="No Ballots" />
      </Grid>
      <Grid item>
        <Typography
          component="span"
          variant="h5"
          className={classes.text}
        >
          {t('no_photos')}
        </Typography>
      </Grid>
    </Grid>
  );
}
