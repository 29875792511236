import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    background: (props) => (props.disabled ? '#C4C4C4' : theme.palette.primary.main),
    border: 0,
    borderRadius: 0,
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    fontWeight: 600,
    color: theme.palette.white,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    padding: '26px 50px',
    maxWidth: '100%',
    transition: 'all 0.3s ease',
    textAlign: 'center',
    opacity: (props) => (props.disabled ? '0.7' : '1'),
    pointerEvents: (props) => (props.disabled ? 'none' : 'auto'),
    '&:hover': {
      color: theme.palette.primary.mainDark,
      background: theme.palette.primary.main,
      boxShadow: `6px 6px 0 0 ${theme.palette.primary.mainDark}`,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      padding: '20px 10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: (props) => (props.smallfont ? '1.2rem' : '1.75rem'),
    },
  },
}));

export default function ButtonBlue({ href, disabled, smallfont, onClick, children }) {
  const classes = useStyles({ disabled, smallfont });
  return (
    <Button
      variant="contained"
      rel="noreferrer"
      target="_blank"
      disableElevation
      className={classes.button}
      href={href}
      size="large"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

ButtonBlue.defaultProps = {
  href: '',
  disabled: false,
  smallfont: false,
};

ButtonBlue.propTypes = {
  disabled: PropTypes.bool,
  smallfont: PropTypes.bool,
  href: PropTypes.string,
};
