import { createMuiTheme } from '@material-ui/core/styles';

import MontserratRegularTtf from '~/assets/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratRegularOtf from '~/assets/fonts/Montserrat/Montserrat-Regular.otf';
import MontserratRegularWoff from '~/assets/fonts/Montserrat/Montserrat-Regular.woff';
import MontserratBoldTtf from '~/assets/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratBoldOtf from '~/assets/fonts/Montserrat/Montserrat-Bold.otf';
import MontserratBoldWoff from '~/assets/fonts/Montserrat/Montserrat-Bold.woff';

import IgraSansTtf from '~/assets/fonts/IgraSans/Igrasans.ttf';
import IgraSansWoff from '~/assets/fonts/IgraSans/Igrasans.woff';
import IgraSansWoff2 from '~/assets/fonts/IgraSans/Igrasans.woff2';

const montserrat = {
  fontFamily: 'Montserrat',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
  local('Montserrat-Regular'),
  url(${MontserratRegularTtf}) format('ttf'),
  url(${MontserratRegularOtf}) format('otf'),
  url(${MontserratRegularWoff}) format('woff')
`,
};

const montserratBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
  local('Montserrat-Bold'),
  url(${MontserratBoldTtf}) format('ttf'),
  url(${MontserratBoldOtf}) format('otf'),
  url(${MontserratBoldWoff}) format('woff')
`,
};

const igrasans = {
  fontFamily: 'Igra Sans',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
  local('IgraSans'),
  url(${IgraSansWoff2}) format('woff2'),
  url(${IgraSansWoff}) format('woff'),
  url(${IgraSansTtf}) format('ttf')
`,
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    white: '#FFFFFF',
    gray: '#F3F3F3',
    blue: '#54B6F9',
    primary: {
      main: '#54B6F9',
      mainDark: '#1467a0',
    },
    secondary: {
      main: '#1A1A1A',
    },
    text: {
      primary: '#000',
    },
    error: {
      main: '#F20C0C',
    },
  },
});

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [montserrat, montserratBold],
      body: {
        backgroundColor: 'white',
      },
      em: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      p: {
        marginTop: 0,
      },
      a: {
        color: '#54B6F9',
      },
    },
  },
  MuiTypography: {
    body1: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.5rem',
    },
    h1: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.9rem',
        lineHeight: '1.9rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.8rem',
        lineHeight: '2.8rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.7rem',
        lineHeight: '3.7rem',
      },
      fontWeight: '600',
      color: theme.palette.text.primary,
    },
    h2: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.up('xs')]: {
        fontSize: '2.1rem',
        lineHeight: '2.1rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
        lineHeight: '2.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.3rem',
        lineHeight: '3.3rem',
      },
      fontWeight: '600',
    },
    h3: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.8rem',
        lineHeight: '2.2rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.1rem',
        lineHeight: '2.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.5rem',
        lineHeight: '2.9rem',
      },
      fontWeight: '600',
    },
    h4: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1.1rem',
        lineHeight: '1.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
        lineHeight: '1.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.3rem',
        lineHeight: '1.5rem',
      },
      fontWeight: '600',
    },
    h5: {
      fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
      [theme.breakpoints.up('xs')]: {
        fontSize: '1rem',
        lineHeight: '1rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
      },
      fontWeight: '600',
    },
  },
  MuiMenuItem: {
    root: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      '&$selected': {
        color: '#54B6F9',
        backgroundColor: 'white',
      },
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: 0,
    },
    list: {
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
    },
  },
  MuiSelect: {
    select: {
      '&.MuiSelect-select': {
        paddingRight: '2rem',
      },
      '&:focus': {
        backgroundColor: 'white',
      },
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItem: {
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  MuiLink: {
    root: {
      textTransform: 'uppercase',
      fontSize: '0.9rem',
    },
  },
};

export default theme;
