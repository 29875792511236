import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useTranslation } from 'react-i18next';
import { getPollingStationNumber } from '~/helpers/numberFormatter';

const useStyles = makeStyles((theme) => ({
  addressItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  stationDetails: {
    borderBottom: '1px solid black',
    paddingBottom: theme.spacing(1),
  },
  stationAddress: {
    marginTop: theme.spacing(1),
  },
  stationCode: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  allStationsItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
}));

export default function PollingStationInfo({ address, statistics }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
    >
      <Grid item sm={12} md={9}>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={2}>
                {/* Empty */}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.allStationsItem}>
                <ArrowBackIcon
                  fontSize="small"
                  color="primary"
                />
                <Box mr={1} />
                <Link
                  align="center"
                  variant="body1"
                  href="/"
                  color="primary"
                  underline="always"
                >
                  {t('all_stations')}
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={2}>
                {/* Empty */}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.addressItem}>
                <Grid
                  container
                  className={classes.stationDetails}
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} md={6} className={classes.stationCode}>
                    <Typography
                      component="span"
                      variant="h2"
                      noWrap={false}
                    >
                      {t('участок')}
                      &nbsp;
                      {getPollingStationNumber(address.code)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={{ fontSize: '0.9rem' }}>
                      {address.address}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="h5"
                  className={classes.stationAddress}
                >
                  <>
                    {address.locality}
                  </>

                  {address.region
                    ? (
                      <>
                        <span>, </span>
                        {address.region}
                        <span>
                          &nbsp;
                          {t('область')}
                        </span>
                      </>
                    ) : null}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PollingStationInfo.propTypes = {
  address: PropTypes.shape({
    region: PropTypes.string,
    locality: PropTypes.string,
    address: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  statistics: PropTypes.exact({
    registered: PropTypes.number,
    monitoredVotes: PropTypes.number,
    officialVotes: PropTypes.number,
    voices: PropTypes.number,
    photoVoices: PropTypes.number,
    choices: PropTypes.object,
  }).isRequired,
};
