import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import { toLocale } from '~/helpers/numberFormatter';
import AsideItem from '~/components/AsideItem';
import OfficialTurnout from '~/assets/images/official-turnout.svg';
import People from '~/assets/images/people.svg';
import Photos from '~/assets/images/photos.svg';
import TotalVoted from '~/assets/images/totalVoted.svg';
import Voice from '~/assets/images/voice.svg';

const useStyles = makeStyles((theme) => ({
}));

export default function AllStatistics({ totalVoted, statistics }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={3} style={{ backgroundColor: '#252525' }}>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-end"
      >
        <AsideItem
          image={
            <img width={35} src={TotalVoted} alt="Всего голосов" />
          }
          text={(
            <>
              <Typography
                component="span"
                variant="h3"
              >
                {toLocale(totalVoted.TotalVoted)}
              </Typography>
              <br />
              <Typography
                component="span"
              >
                {t('total_num_of_voices')}
              </Typography>
            </>
          )}
        />

        <AsideItem
          image={
            <img width={35} src={Voice} alt="Уникальных подтвержденных голосов на платформе Голос" />
          }
          text={(
            <>
              <Typography
                component="span"
                variant="h3"
              >
                {toLocale(statistics.registered)}
              </Typography>
              <br />
              <Typography
                component="span"
              >
                {t('unique_confirmed')}
              </Typography>
            </>
          )}
        />

        <AsideItem
          last
          image={
            <img width={35} src={Photos} alt="Сфотографированных бюллетеней на платформе Голос" />
          }
          text={(
            <>
              <Typography
                component="span"
                variant="h3"
              >
                {toLocale(statistics.photoVoices)}
              </Typography>
              <br />
              <Typography
                component="span"
              >
                {t('фото в системе')}
              </Typography>
            </>
          )}
        />

        {/* <AsideItem
          image={
            <img height={35} src={OfficialTurnout} alt="Официальная явка" />
          }
          text={(
            <>
              <Typography
                component="span"
                variant="h3"
              >
                {toLocale(statistics.officialVotes)}
              </Typography>
              <br />
              <Typography
                component="span"
              >
                {t('официальная явка')}
              </Typography>
            </>
          )}
        /> */}

        {/* <AsideItem
          last
          image={
            <img width={40} src={People} alt="Избиратели" />
          }
          text={
            statistics.officialVoters === 0
              ? (
                <Typography
                  component="span"
                  noWrap={false}
                >
                  {t('processing_voters')}
                </Typography>
              ) : (
                <>
                  <Typography
                    component="span"
                    variant="h3"
                  >
                    {toLocale(statistics.officialVoters)}
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    noWrap={false}
                  >
                    {t('voters')}
                  </Typography>
                </>
              )
          }
        /> */}
      </Grid>
    </Grid>
  );
}

AllStatistics.propTypes = {
  statistics: PropTypes.objectOf(PropTypes.any).isRequired,
  totalVoted: PropTypes.objectOf(PropTypes.number).isRequired,
};
