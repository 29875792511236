const GTAG_ID = 'UA-173158975-3';
const LANDING_URL = 'https://golos.partizan-results.com/';
const RESULTS_URL = 'https://partizan-results.com';
const WALKING_URL = 'https://partizan-results.com/go';
const MAP_API_KEYS = '43d1a75b-a259-42d5-9b63-c4b50cdf14c4,d618619a-c67f-43c4-b832-05312f126771';
const VIBER_BOT_URL = 'viber://pa?chatURI=golos-by';
const TELEGRAM_BOT_URL = 'https://t.me/golosby_bot';
const FAQ_URL = 'https://golos.partizan-results.com/faq.html';
const API_BASE_URL = 'https://d3kxokrdpc64vo.cloudfront.net';
const STATS_BUCKET_URL = 'https://d3kxokrdpc64vo.cloudfront.net/stats.json';
const GO_PATH = '/go';
const TERMS_URL = 'https://golos.partizan-results.com/terms.html';
const GO_ENG_PATH = '/supportbelarus';
const GO_ENG_URL = 'https://partizan-results.com/supportbelarus';
const ABROAD_CHECKIN_API = 'https://on8fmdmh1j.execute-api.us-east-1.amazonaws.com/Prod/checkin';
const POLLING_STARTIONS_API = 'https://on8fmdmh1j.execute-api.us-east-1.amazonaws.com/Prod/Quarantine';

module.exports = {
  GTAG_ID,
  LANDING_URL,
  RESULTS_URL,
  MAP_API_KEYS,
  VIBER_BOT_URL,
  TELEGRAM_BOT_URL,
  WALKING_URL,
  FAQ_URL,
  TERMS_URL,
  API_BASE_URL,
  STATS_BUCKET_URL,
  GO_PATH,
  GO_ENG_PATH,
  GO_ENG_URL,
  ABROAD_CHECKIN_API,
  POLLING_STARTIONS_API,
};
