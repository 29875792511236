import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { HashLink as SmoothLink } from 'react-router-hash-link';
import HeaderCall from '~/components/HeaderCall';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  buttonItem: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      // display: 'flex',
      display: 'none',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  button: {
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: '1.5rem',
    fontWeight: '600',
    textDecoration: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '3px 3px 0 0 #1467a0',
      color: '#1467a0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.5),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      fontSize: '1.1rem',
    },
  },
  titleItem: {
    color: 'white',
    paddingRight: theme.spacing(1),
  },
  header: {
    textTransform: 'uppercase',
    marginBottom: '0',
    [theme.breakpoints.down('xs')]: {
      // marginBottom: theme.spacing(3),
    },
  },
}));

export default function WalkingCall() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <HeaderCall>
      <Grid
        container
      >
        {/* <Grid item xs={12} sm={8} md={9} className={classes.titleItem}> */}
        <Grid item xs={12} sm={12} md={12} className={classes.titleItem}>
          <Typography
            component="h1"
            variant="h3"
            className={classes.header}
          >
            {t('walking_call_header')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          className={classes.buttonItem}
        >
          <SmoothLink
            smooth
            className={classes.button}
            to="#how-works"
          >
            {t('walking_call_button')}
          </SmoothLink>
        </Grid>
      </Grid>
    </HeaderCall>
  );
}
