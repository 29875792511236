import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Select as MUISelect, Typography, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  menuItem: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
});

const Select = ({
  defaultValue, onChange, items,
}) => {
  const classes = useStyles();
  const [language, setLanguage] = useState(defaultValue);

  const handleChange = (event) => {
    const { target: { value } } = event;
    setLanguage(value);
    onChange(value);
  };
  return (
    <MUISelect
      labelId="holas-select-label"
      id="holas-select"
      defaultValue={defaultValue}
      onChange={handleChange}
      value={language}
    >
      {items.map(({ id, content }) => (
        <MenuItem key={id} value={id}>
          <Typography
            className={classes.menuItem}
          >
            {content}
          </Typography>
        </MenuItem>
      ))}
    </MUISelect>
  );
};

export default Select;

Select.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  })).isRequired,
};
