const config = require('./config');

const gtagId = config.GTAG_ID;
const resultsUrl = process.env.RESULTS_URL || config.RESULTS_URL;
const mapApiKeys = process.env.MAP_API_KEYS || config.MAP_API_KEYS;
const apiBaseUrl = process.env.API_BASE_URL || config.API_BASE_URL;
const goPath = config.GO_PATH;
const goEngPath = config.GO_ENG_PATH;
const cookieAbroadSupport = 'check_in';
// use REACT_APP_ prefix for variables needed in build
const faqUrl = process.env.REACT_APP_FAQ_URL || config.FAQ_URL;
const landingUrl = process.env.REACT_APP_LANDING_URL || config.LANDING_URL;
const statsBucketUrl = process.env.REACT_APP_STATS_BUCKET_URL || config.STATS_BUCKET_URL;
const viberBotUrl = process.env.REACT_APP_VIBER_BOT_URL || config.VIBER_BOT_URL;
const telegramBotUrl = process.env.REACT_APP_TELEGRAM_BOT_URL || config.TELEGRAM_BOT_URL;
const walkingUrl = process.env.REACT_APP_WALKING_URL || config.WALKING_URL;
const termsUrl = process.env.REACT_APP_TERMS_URL || config.TERMS_URL;
const goEngUrl = process.env.REACT_APP_GO_ENG_URL || config.GO_ENG_URL;
const abroadCheckinApi = process.env.REACT_APP_ABROAD_CHECKIN_API || config.ABROAD_CHECKIN_API;
const pollingStationsApi = process.env.REACT_APP_POLLING_STATIONS_API || config.POLLING_STARTIONS_API;

module.exports = {
  gtagId,
  landingUrl,
  resultsUrl,
  mapApiKeys,
  viberBotUrl,
  telegramBotUrl,
  walkingUrl,
  faqUrl,
  termsUrl,
  apiBaseUrl,
  statsBucketUrl,
  goPath,
  goEngPath,
  goEngUrl,
  abroadCheckinApi,
  cookieAbroadSupport,
  pollingStationsApi,
};
