/* global ymaps */
import { has, findIndex } from 'lodash';

import { getQuery, pushState, listenPopState } from '../common/url';
import {
  getMap, syncCenterToUrl, askGeolocation, getOpenStation,
} from '../common/map';
import StationIconBlue from '~/assets/images/station-icon-blue.svg';
import StationIconRed from '~/assets/images/station-icon-red.svg';
import StationIconGray from '~/assets/images/station-icon-gray.svg';
import StationIconRedBlueMix from '~/assets/images/station-icon-mix-red-blue.svg';
import StationIconRedGrayMix from '~/assets/images/station-icon-mix-red-gray.svg';
import StationIconBlueGrayMix from '~/assets/images/station-icon-mix-blue-gray.svg';

import {
  getSearchControl,
  getZoomControl,
  getGeolocationControl,
} from '../common/controls';

import { getStationsData, getBalloonContent } from './stations';
import { MAX_ZOOM } from '../common/constants';

let yamapsReady;
let showStations;

export const all = (onPollingStationClicked) => {
  let notifyMapsReady;
  yamapsReady = new Promise((resolve) => {
    notifyMapsReady = resolve;
  });

  let mapInstance;
  let objectManager;
  let openStation;

  const setBaloon = (station) => {
    objectManager.objects.setObjectProperties(station.id, {
      balloonContent: getBalloonContent(station.properties.data),
    });
  };

  const getObjectManager = () => new ymaps.ObjectManager(
    {
      clusterize: true,
      geoObjectOpenBalloonOnClick: false,
      geoObjectIconImageSize: [24, 24],
      geoObjectIconImageOffset: [-12, -12],
      hideIconOnBalloonOpen: false,
      gridSize: 64,
      clusterHasBalloon: false,
    },
    {
      showInAlphabeticalOrder: true,
    },
  );

  const getIcon = (cluster) => {
    const hasForged = cluster.features.some((x) => has(x.properties, 'forged') && x.properties.forged);
    const hasHonest = cluster.features.some((x) => has(x.properties, 'forged') && !x.properties.forged);
    const hasNoData = cluster.features.some((x) => !has(x.properties, 'forged'));

    // forged combination
    if (hasForged) {
      if (hasHonest) {
        return StationIconRedBlueMix;
      }
      if (hasNoData) {
        return StationIconRedGrayMix;
      }
      return StationIconRed;
    }

    // honest combination
    if (hasHonest) {
      if (hasNoData) {
        return StationIconBlueGrayMix;
      }
      return StationIconBlue;
    }

    return StationIconGray;
  };

  const init = () => {
    mapInstance = getMap();
    objectManager = getObjectManager();

    objectManager.clusters.events.add('add', (e) => {
      const cluster = e.get('child');
      objectManager.clusters.setClusterOptions(cluster.id, {
        clusterIcons: [
          {
            href: getIcon(cluster),
            size: [36, 36],
            offset: [-18, -18],
          }],
        clusterIconContentLayout: null,
      });
    });

    mapInstance.geoObjects.add(objectManager);
    openStation = getOpenStation({ mapInstance, objectManager, setBaloon });

    mapInstance.controls.add(getSearchControl());
    mapInstance.controls.add(getZoomControl());
    mapInstance.controls.add(getGeolocationControl());

    askGeolocation({ mapInstance });
    listenPopState({ mapInstance, openStation });
    notifyMapsReady();
  };

  const getCluster = (event) => objectManager.clusters.getById(event.get('objectId'));
  const centerToObject = (coordinates) => {
    const viewportWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    if (viewportWidth > 960) {
      const area = {
        top: 0,
        right: 0,
        width: '517px',
        height: '100%',
      };
      const accessor = mapInstance.margin.addArea(area);
      mapInstance.panTo(coordinates, { useMapMargin: true });
      accessor.remove();
    }
  };

  showStations = ({ data }) => {
    objectManager.add(data);

    objectManager.objects.events.add(['click'], (event) => {
      const stationId = event.get('objectId');
      pushState({ station: stationId });
      centerToObject(event.get('coords'));
      onPollingStationClicked([stationId]);
    });

    objectManager.clusters.events.add(['click'], (event) => {
      const cluster = getCluster(event);
      if (mapInstance.getZoom() === MAX_ZOOM) {
        const clusterPosition = cluster.geometry.coordinates;
        centerToObject(clusterPosition);
        onPollingStationClicked(cluster.features.map((x) => x.id));
      }
    });

    const query = getQuery();
    if (query.station) {
      openStation(query.station, { setCenter: true });
    }

    mapInstance.events.add('actionend', () => syncCenterToUrl(mapInstance));
  };

  /*
   *    MAIN
   */

  function initMaps() {
    if (![undefined, 'undefined'].includes(typeof ymaps)) {
      ymaps.ready(init);
    } else {
      setTimeout(() => initMaps(),500)
    }
  }

  initMaps();
};

export function setPollingStations(pollingstations) {
  const data = getStationsData(pollingstations);
  yamapsReady.then(() => showStations(data));
}
