import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  author: {
    color: theme.palette.white,
    fontSize: '1rem',
    fontWeight: 600,
    textTransform: 'lowercase',
    '& a': {
      color: theme.palette.white,
      fontSize: '1rem',
      textTransform: 'uppdercase',
      fontWeight: 'bold',
    },
  },
}));

export default function Author({ title, name, href }) {
  const classes = useStyles();

  return (
    <Typography
      component="div"
      className={classes.author}
    >
      {title}
      <Link
        href={href}
        target="_blank"
        underline="always"
      >
        {name}
      </Link>
    </Typography>
  );
}

Author.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
