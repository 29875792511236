import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingTop: theme.spacing(5),
  },
  empty: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  detailedReport: {
    marginTop: theme.spacing(5),
    background: '#f0f0f0',
  },
  image: {
    marginTop: theme.spacing(0.6),
    marginRight: theme.spacing(2.5),
  },
  titleItem: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    marginBottom: theme.spacing(5),
  },
}));

export default function PhotosDisclaime() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={9} className={classes.detailedReport}>
        <Grid
          container
          alignItems="flex-start"
        >
          <Grid item md={2}>
            {/* Empty */}
          </Grid>

          <Grid item xs={10} className={classes.titleItem}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item>
                <WarningIcon
                  color="primary"
                  fontSize="large"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography
                  component="span"
                  variant="body1"
                >
                  {t('photos_disclaimer')}
                </Typography>
                &nbsp;
                <Link
                  variant="body1"
                  href="https://forms.gle/XB6gzx3jw888HSZm8"
                  color="primary"
                  target="_blank"
                  underline="always"
                  style={{ fontWeight: 'bold' }}
                >
                  {t('this_form')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
