import React from 'react';
import {
  Box,
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SupportBelarusFooter600 from '~/assets/images/supportBelarus/support-belarus-footer-600.jpg';
import SupportBelarusFooter960 from '~/assets/images/supportBelarus/support-belarus-footer-960.jpg';
import SupportBelarusFooter1440 from '~/assets/images/supportBelarus/support-belarus-footer-1440.jpg';
import SupportBelarusFooter1920 from '~/assets/images/supportBelarus/support-belarus-footer-1920.jpg';
import ImageBackground from './ImageBackground';
import Author from '~/components/Author';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    textTransform: 'uppercase',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '30px',
  },
  title: {
    color: theme.palette.white,
    fontWeight: 600,
    lineHeight: 1,
    paddingBottom: theme.spacing(15),
    paddingTop: theme.spacing(15),
    maxWidth: '100%',
    [theme.breakpoints.up('xs')]: {
      fontSize: '60px',
      paddingBottom: theme.spacing(20),
      paddingTop: theme.spacing(20),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: (props) => (props.language === 'en' ? '120px' : '70px'),
      paddingBottom: theme.spacing(20),
      paddingTop: theme.spacing(20),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: (props) => (props.language === 'en' ? '180px' : '120px'),
      paddingBottom: theme.spacing(25),
      paddingTop: theme.spacing(25),
    },
  },
}));

export default function SupportBelarusFooter() {
  const { t, i18n } = useTranslation();
  const language = i18n.language || window.localStorage.i18nextLng;
  const classes = useStyles({ language });
  return (
    <Grid
      container
      className={classes.root}
    >
      <ImageBackground
        altTKey="support_belarus_background_image"
        srcDefault={
          {
            url: SupportBelarusFooter1440,
            width: 1440,
          }
        }
        srcSet={[
          {
            url: SupportBelarusFooter600,
            width: 375,
          },
          {
            url: SupportBelarusFooter600,
            width: 600,
          },
          {
            url: SupportBelarusFooter960,
            width: 960,
          },
          {
            url: SupportBelarusFooter1920,
            width: 1920,
          },
        ]}
      />
      <Typography
        component="h2"
        variant="h2"
        className={classes.title}
      >
        {t('support_belarus')}
      </Typography>

      <Box mr={5}>
        <Author
          alignSelf="flex-end"
          title={t('photo_by')}
          href="https://instagram.com/kinetos"
          name="kinetos"
        />
      </Box>
    </Grid>
  );
}
