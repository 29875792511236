import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container, Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { get } from '~/services/ResultsService';
import { getPollingStations, getDetached } from '~/redux/selectors';
import BulletinsContainer from './BulletinsContainer';
import Loading from '~/components/Loading';
import MissingPhoto from './MissingPhoto';
import DetachedStationsInfo from './DetachedStationsInfo';
import PollingStationInfo from './PollingStationInfo';
import PollingStationStatistics from './PollingStationStatistics';
import VotingResults from '~/components/VotingResults/VotingResults';

const useStyles = makeStyles((theme) => ({
  results: {
    backgroundColor: '#54B6F9',
    marginTop: '40px',
  },
  black: {
    backgroundColor: '#252525',
  },
}));

function PollingStationContainer({ detached, pollingStations }) {
  const { id } = useParams();
  const stationPresent = id !== 'unassigned';
  const classes = useStyles();
  const [statisticsLoaded, setStatisticsLoaded] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const address = pollingStations && pollingStations.length > 0
    ? pollingStations.find((station) => station.code === id) : null;

  useEffect(() => {
    if (stationPresent) {
      get(id).then(
        (stats) => {
          setStatistics(stats);
          setStatisticsLoaded(true);
        },
        () => {
          // TODO error handling
          setStatisticsLoaded(true);
        },
      );
    } else {
      setStatisticsLoaded(true);
    }
  }, [id, stationPresent]);

  return (
    <Container component="main" maxWidth={false} disableGutters>
      <Grid
        container
        alignItems="center"
        style={{ marginBottom: '40px' }}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          stationPresent
            ? (
              statisticsLoaded && address
                ? <PollingStationInfo address={address} statistics={statistics} />
                : <Loading />
            )
            : detached ? <DetachedStationsInfo detached={detached} /> : <Loading />
        }
      </Grid>

      <Grid
        container
        className={clsx(classes.results, { [classes.black]: stationPresent })}
      >
        {
          detached && statisticsLoaded
            ? (
              <VotingResults
                statistics={stationPresent ? statistics : detached}
                showPhotoData
                showOfficialData={stationPresent}
                showIgnoreElection={false}
              />
            ) : <Loading />
        }

        {
          // eslint-disable-next-line no-nested-ternary
          stationPresent
            ? (
              statisticsLoaded
                ? <PollingStationStatistics statistics={statistics} />
                : <Loading />
            )
            : null
        }
      </Grid>

      <BulletinsContainer pollingStationId={id} />

      {stationPresent && detached && <MissingPhoto detached={detached} />}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  pollingStations: getPollingStations(state),
  detached: getDetached(state),
});

export default connect(mapStateToProps)(PollingStationContainer);
