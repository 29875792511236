import { has } from 'lodash';

import { formatPluralWithValue } from '../common/formatters';
import StationIconBlue from '~/assets/images/station-icon-blue.svg';
import StationIconRed from '~/assets/images/station-icon-red.svg';
import StationIconGray from '~/assets/images/station-icon-gray.svg';

export const getStationsData = (stations) => {
  const data = {
    type: 'FeatureCollection',
    features: new Array(stations.length),
  };

  const getIcon = (station) => {
    const whitelisted = has(station, 'forged');
    if (whitelisted && station.forged) {
      return StationIconRed;
    }

    if (whitelisted && !station.forged) {
      return StationIconBlue;
    }

    return StationIconGray;
  };

  for (let i = 0; i < stations.length; i += 1) {
    const station = stations[i];
    const forgedProps = {};

    if (has(station, 'forged')) {
      forgedProps.forged = station.forged;
    }

    if (Number.isNaN(station.latitude) || Number.isNaN(station.longitude)) {
      station.latitude = 0;
      station.longitude = 0;
    }

    data.features[i] = {
      type: 'Feature',
      id: station.code,
      geometry: {
        type: 'Point',
        coordinates: [station.longitude, station.latitude],
      },
      options: {
        iconImageHref: getIcon(station),
        iconLayout: 'default#image',
      },
      properties: forgedProps,
    };
  }

  return { data };
};

export const getBalloonContent = ({ description, golosCount }) => `
  <div>
    <label>${description}</label>
    <br>
    <br>
    <label><strong>${formatPluralWithValue(golosCount, {
  one: 'голос',
  few: 'голоса',
  other: 'голосов',
})}</strong> на участке</label>
  </div>
`;
