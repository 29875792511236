import React from 'react';
import {
  Grid,
  Typography,
  Link,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

import { toLocale } from '~/helpers/numberFormatter';

const useStyles = makeStyles((theme) => ({
  addressItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  stationDetails: {
    borderBottom: '1px solid black',
    paddingBottom: theme.spacing(1),
  },
  stationAddress: {
    marginTop: theme.spacing(1),
  },
  stationCode: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  allStationsItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
    },
  },
}));

export default function DetachedStationsInfo({ detached }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
    >
      <Grid item sm={12} md={9}>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={2}>
                {/* Empty */}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.allStationsItem}>
                <ArrowBackIcon
                  fontSize="small"
                  color="primary"
                />
                <Box mr={1} />
                <Link
                  align="center"
                  variant="body1"
                  href="/"
                  color="primary"
                  underline="always"
                >
                  {t('all_stations')}
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
            >
              <Grid item md={2}>
                {/* Empty */}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.addressItem}>
                <Grid
                  container
                  className={classes.stationDetails}
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} className={classes.stationCode}>
                    <Typography
                      component="span"
                      variant="h2"
                      noWrap={false}
                    >
                      Голоса без участка
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="h5"
                  className={classes.stationAddress}
                >
                  {t('missing_photo2')}
                  {t('missing_photo3', { registered: toLocale(detached.registered) })}
                  {t('missing_photo4', { photoVoices: toLocale(detached.photoVoices) })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
