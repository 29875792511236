import React from 'react';
import {
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { goEngUrl } from '../../../globals';
import ButtonBlue from '../../components/ButtonBlue';
import Flags from '~/assets/images/supportBelarus/flags.svg';
import { separateDigits, toLocale } from '~/helpers/numberFormatter';

const useStyles = makeStyles((theme) => ({
  coloredBg: {
    paddingTop: '60px',
    background: theme.palette.white,
  },
  title: {
    textTransform: 'uppercase',
    maxWidth: '700px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  padding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(8),
    },
  },
  desc: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.13rem',
      marginTop: theme.spacing(5),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& img': {
      marginTop: 'auto',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(9),
    },
  },
}));

export default function SupportBelarus({ SupportersAbroad }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.coloredBg}
      >
        <Grid item xs={12} md={9} className={classes.padding}>
          <Grid
            container
          >
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h2"
                className={classes.title}
              >
                {t('support_belarus_header')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.desc}>
                {/* <ButtonBlue href={goEngUrl}> */}
                {/*  {t('link_to_support_belarus_button', { SupportersAbroad: separateDigits(SupportersAbroad) })} */}
                {/* </ButtonBlue> */}
                <ButtonBlue href={goEngUrl} smallfont>
                  {t('link_to_support_belarus_temp')}
                </ButtonBlue>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={classes.imageContainer}
            >
              <img src={Flags} alt={t('support_flags')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

SupportBelarus.propTypes = {
  SupportersAbroad: PropTypes.number.isRequired,
};
