import React, { useState } from 'react';
import { Box, Drawer, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Map from './Map';
import PollingStationSummary from './PollingStationSummary';

const styles = (theme) => ({
  mapContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  drawerPaper: {
    [theme.breakpoints.up('xs')]: {
      border: 0,
      position: 'fixed',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      border: '1px solid #ccc',
      position: 'absolute',
      width: '517px',
    },
  },
});

class MapContainer extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      pollStationSummaryShown: false,
      pollingStationIds: [],
    }
  }

  handlePollingStationClick = (newPollingStationIds) => {
    const {pollingStationIds} = this.state
    if (pollingStationIds.length !== newPollingStationIds.length
      || pollingStationIds.some((id, index) => id !== newPollingStationIds[index])) {
      this.setState({
        pollingStationIds: newPollingStationIds,
        pollStationSummaryShown: true
      });
    } else {
      this.setState(s => ({pollStationSummaryShown: !s.pollStationSummaryShown}));
    }
  };

  render() {
    const { classes } = this.props;
    const { pollStationSummaryShown, pollingStationIds } = this.state;
    return (
      <Grid
        container
        justify="center"
      >
        <Grid item xs={11}>
          <Box className={classes.mapContainer}>
            <Map onPollingStationClicked={this.handlePollingStationClick}
            />
            <Drawer
              open={pollStationSummaryShown}
              anchor="right"
              variant="persistent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <PollingStationSummary
                pollingStationIds={pollingStationIds}
                onClose={() => this.setState({pollStationSummaryShown:false})}
              />
            </Drawer>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(MapContainer);
