import React from 'react';
import {
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { previousSundayDate, protestFormat } from '~/helpers/countersTexts';
import SupportBelarus600 from '~/assets/images/supportBelarus/support-belarus-600.jpg';
import SupportBelarus960 from '~/assets/images/supportBelarus/support-belarus-960.jpg';
import SupportBelarus1440 from '~/assets/images/supportBelarus/support-belarus-1440.jpg';
import SupportBelarus1920 from '~/assets/images/supportBelarus/support-belarus-1920.jpg';
// eslint-disable-next-line import/extensions,import/no-unresolved
import Counter from '~/components/Walking/Counter';
// eslint-disable-next-line import/extensions,import/no-unresolved
import Author from '~/components/Author';
import ImageBackground from './ImageBackground';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  shadow: {
    background: 'linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.1) 100%)',
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    width: '100%',
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      width: '66.66667%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  counters: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    marginLeft: 'auto',
    maxWidth: '580px',
  },
  totalTitle: {
    color: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  padding: {
    paddingLeft: '13.111113%',
    paddingRight: '8.111113%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

// eslint-disable-next-line no-unused-vars
export default function Counters({ totalWalking, SupportersAbroad }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const currentLanguage = i18n.language || window.localStorage.i18nextLng;

  return (
    <Grid
      container
      className={classes.root}
    >
      <ImageBackground
        altTKey="support_belarus_background_image"
        srcDefault={
          {
            url: SupportBelarus1440,
            width: 1440,
          }
        }
        srcSet={[
          {
            url: SupportBelarus600,
            width: 375,
          },
          {
            url: SupportBelarus600,
            width: 600,
          },
          {
            url: SupportBelarus960,
            width: 960,
          },
          {
            url: SupportBelarus1920,
            width: 1920,
          },
        ]}
      />
      <Box className={classes.shadow} />
      <Grid item xs={12} className={classes.padding}>
        <Grid
          container
        >
          <Grid item xs={12} sm={4} md={6}>
            {/* Empty */}
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={6}
          >
            <Box className={classes.counters}>
              <Typography
                component="h2"
                variant="h3"
                color="primary"
                className={classes.totalTitle}
              >
                {t('support_belarus_counters_title_total')}
              </Typography>

              <Counter total={SupportersAbroad} type="light" />

              <Box mt={6} />

              {/* <Typography */}
              {/*  component="h2" */}
              {/*  variant="h3" */}
              {/*  className={classes.totalTitle} */}
              {/* > */}
              {/*  {t('support_belarus_counters_title_total_walking', { */}
              {/*    Sunday: previousSundayDate(currentLanguage), */}
              {/*    Protest: protestFormat(currentLanguage), */}
              {/*  })} */}
              {/* </Typography> */}

              {/* <Counter total={totalWalking} type="light" /> */}

              <Box mt={10} />

              <Author
                title={t('photo_by')}
                href="https://instagram.com/kinetos"
                name="kinetos"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
Counters.defaultProps = {
  totalWalking: 0,
  SupportersAbroad: 0,
};

Counters.propTypes = {
  totalWalking: PropTypes.number,
  SupportersAbroad: PropTypes.number,
};
