import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {Box, Grid, Typography} from '@material-ui/core';
import ShareButtons from '../../components/ShareButtons';
import Flags from '~/assets/images/supportBelarus/flags.svg';
import Icon0 from '~/assets/images/supportBelarus/support_icon_0.svg';
import Icon1 from '~/assets/images/supportBelarus/support_icon_1.svg';
import Icon2 from '~/assets/images/supportBelarus/support_icon_2.svg';
import Icon3 from '~/assets/images/supportBelarus/support_icon_3.svg';
import { goEngUrl } from '../../../globals';
import SupportBelarusButton from '~/components/SupportBelarus/SupportBelarusButton';
import SwipeableImageTabs from '../../components/SwipeableImageTabs';
import WalkingResult from '../AllResults/WalkingResult';
import WalkResultsBy960 from '~/assets/images/walking/walk-results-by-960.jpg';
import WalkResultsBy1440 from '~/assets/images/walking/walk-results-by-1440.jpg';
import WalkResultsBy1920 from '~/assets/images/walking/walk-results-by-1920.jpg';
import WalkResultsEu960 from '~/assets/images/walking/walk-results-eu-960.jpg';
import WalkResultsEu1440 from '~/assets/images/walking/walk-results-eu-1440.jpg';
import WalkResultsEu1920 from '~/assets/images/walking/walk-results-eu-1920.jpg';
import WalkResultsWorld960 from '~/assets/images/walking/walk-results-world-960.jpg';
import WalkResultsWorld1440 from '~/assets/images/walking/walk-results-world-1440.jpg';
import WalkResultsWorld1920 from '~/assets/images/walking/walk-results-world-1920.jpg';

const walkingResultConfig = [
  {
    id: 'world',
    title: 'walking_world',
    image: {
      srcDefault: {
        url: WalkResultsWorld1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsWorld960,
          width: 960,
        },
        {
          url: WalkResultsWorld1920,
          width: 1920,
        },
      ],
    },
  },
  {
    id: 'europe',
    title: 'walking_eu',
    image: {
      srcDefault: {
        url: WalkResultsEu1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsEu960,
          width: 960,
        },
        {
          url: WalkResultsEu1920,
          width: 1920,
        },
      ],
    },
  },
  {
    id: 'belarus',
    title: 'walking_by',
    image: {
      srcDefault: {
        url: WalkResultsBy1440,
        width: 1440,
      },
      srcSet: [
        {
          url: WalkResultsBy960,
          width: 960,
        },
        {
          url: WalkResultsBy1920,
          width: 1920,
        },
      ],
    },
  },
];

const useStyles = makeStyles((theme) => ({
  coloredBg: {
    background: theme.palette.gray,
  },
  padding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  title: {
    textTransform: 'uppercase',
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  desc: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.13rem',
      marginTop: theme.spacing(5),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& img': {
      marginTop: 'auto',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(9),
    },
  },
  sharingDesc: {
    fontSize: '0.8rem',
  },
  shareContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      order: 0,
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(7),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(4),
    },
  },
  iconImg: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  iconTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    maxWidth: '464px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    },
  },
  info: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(18),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.13rem',
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
    },
  },
}));

function SupportContent() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.coloredBg}
      >
        <Grid item xs={12} md={9} className={classes.padding}>
          <Grid
            container
          >
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h2"
                className={classes.title}
              >
                {t('support_button_header')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                component="p"
                className={classes.desc}
              >
                {t('support_button_desc')}
              </Typography>
              <Box className={classes.desc}>
                <SupportBelarusButton />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={classes.imageContainer}
            >
              <img src={Flags} alt={t('support_flags')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <WalkingResult title="walking_result_supporters_title" />
      <SwipeableImageTabs tabs={walkingResultConfig} />

      <Grid
        container
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={9} className={classes.padding}>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} md={3} className={classes.shareContainer}>
              <Typography
                component="p"
                className={classes.sharingDesc}
              >
                {t('support_share_desc')}
              </Typography>
              <ShareButtons
                align="left"
                share={['vk', 'facebook', 'odnoklassniki', 'twitter']}
                url={goEngUrl}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography
                component="h3"
                variant="h2"
                className={classes.title}
              >
                {t('support_header')}
              </Typography>
              <Typography
                component="p"
                className={classes.desc}
              >
                {t('support_desc_0')}
              </Typography>
              <Typography
                component="p"
                className={classes.desc}
              >
                {t('support_desc_1')}
              </Typography>
              <Typography
                component="p"
                className={classes.desc}
              >
                {t('support_desc_2')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.coloredBg}
      >
        <Grid item xs={12} md={9} className={classes.padding}>
          <Grid
            container
          >
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h2"
                className={classes.title}
              >
                {t('support_icons_header')}
              </Typography>
            </Grid>
            <Grid
              container
            >
              <Grid item xs={12} md={6} className={classes.icon}>
                <img src={Icon0} alt={t('support_icon_0')} className={classes.iconImg} />
                <Typography
                  component="p"
                  className={classes.iconTitle}
                >
                  {t('support_icons_title_0')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.icon}>
                <img src={Icon1} alt={t('support_icon_1')} className={classes.iconImg} />
                <Typography
                  component="p"
                  className={classes.iconTitle}
                >
                  {t('support_icons_title_1')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.icon}>
                <img src={Icon2} alt={t('support_icon_2')} className={classes.iconImg} />
                <Typography
                  component="p"
                  className={classes.iconTitle}
                >
                  {t('support_icons_title_2')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.icon}>
                <img src={Icon3} alt={t('support_icon_3')} className={classes.iconImg} />
                <Typography
                  component="p"
                  className={classes.iconTitle}
                >
                  {t('support_icons_title_3')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="p"
                className={classes.info}
              >
                {t('support_icons_desc')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SupportContent;
