import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Container, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// TODO: Replace with call to action with content

const useStyles = makeStyles((theme) => ({
  root: () => ({
    backgroundColor: theme.palette.white,
  }),
  padding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
}));

const WalkingResult = (props) => {
  const {
    title,
  } = props;
  const classes = useStyles({});
  const { t } = useTranslation();

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={9} className={classes.padding}>
          <Typography
            component="h3"
            variant="h3"
          >
            {t(title)}
          </Typography>
          <br />
          <Typography
            component="p"
          >
            <Trans
              i18nKey="walking_result"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

WalkingResult.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WalkingResult;
