export const isSundayToday = new Date().getDay() === 0;

const BELARUSIAN_MONTHS = ['студзеня', 'лютага', 'сакавіка', 'красавіка', 'мая', 'чэрвеня', 'ліпеня', 'жніўня', 'верасня', 'кастрычніка', 'лістапада', 'снежня'];

const WALK_SUNDAY = {
  by: 'Гуляюць',
  en: 'Supporting from their homes',
  ru: 'Гуляют',
};

const WALK_OTHER = {
  by: 'Гулялі',
  en: 'Walked at home',
  ru: 'Гуляли',
};

const PROTEST_SUNDAY = {
  by: 'Падтрымліваюць',
  en: 'Protesting',
  ru: 'Поддерживают',
};

const PROTEST_OTHER = {
  by: 'Падтрымлівалі',
  en: 'Protested',
  ru: 'Поддерживали',
};

const GO_OUT_SUNDAY = {
  by: 'Гуляюць',
  en: 'Are out',
  ru: 'Гуляют',
};

const GO_OUT_OTHER = {
  by: 'Гулялі',
  en: 'Went out',
  ru: 'Гуляли',
};

const getPreviousSunday = () => {
  const today = new Date();
  if (isSundayToday) {
    return today;
  }
  // Calculated previous Sunday
  return new Date(today.getTime() - today.getDay() * 24 * 3600 * 1000);
};

export const previousSundayDate = (language) => {
  const date = getPreviousSunday();

  if (language === 'by') {
    return `${date.getDate()}\xa0${BELARUSIAN_MONTHS[date.getMonth()]}`;
  }
  return date.toLocaleString(language, { day: 'numeric', month: 'long' }).replace(' ', '\xa0');
};

const nextWeekdayDate = (date, dayInWeek) => {
  const ret = new Date(date || new Date());
  // eslint-disable-next-line no-mixed-operators
  ret.setDate(ret.getDate() + (dayInWeek - 1 - ret.getDay() + 7) % 7 + 1);
  return ret;
};

const getNextSunday = () => {
  const today = new Date();
  if (isSundayToday) {
    return today;
  }
  // Calculated next Sunday
  return nextWeekdayDate(today, 0);
};

export const nextSundayDate = (language) => {
  const date = getNextSunday();

  if (language === 'by') {
    return `${date.getDate()}\xa0${BELARUSIAN_MONTHS[date.getMonth()]}`;
  }
  return date.toLocaleString(language, { day: 'numeric', month: 'long' }).replace(' ', '\xa0');
};

export const walkFormat = (language) => (
  isSundayToday ? WALK_SUNDAY[language] : WALK_OTHER[language]
);

export const goOutFormat = (language) => (
  isSundayToday ? GO_OUT_SUNDAY[language] : GO_OUT_OTHER[language]
);

export const protestFormat = (language) => (
  isSundayToday ? PROTEST_SUNDAY[language] : PROTEST_OTHER[language]
);
