import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loading() {
  return (
    <div style={{
      textAlign: 'center',
      padding: '10px',
      width: '100%',
    }}
    >
      <CircularProgress />
    </div>
  );
}
