import React from 'react';
import {
  Grid,
  Typography,
  Link,
  Button,
  Box,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
} from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#1467a0',
    },
  },
}));

export default function ReportPersonalData({
  bulletin,
  onReport,
  onRecaptcaChange,
  onErrorClose,
  valid,
  error
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [reason, setReason] = React.useState('personalData');
  const [otherReason, setOtherReason] = React.useState('');
  const recaptchaRef = React.createRef();
  const reasons = [
    {
      value: 'personalData',
      label: t('notify_personal_data'),
    },
    {
      value: 'wrongCandidate',
      label: t('notify_incorrect_data'),
    },
    {
      value: 'other',
      label: t('notify_other'),
    },
  ];

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  const reportBulletin = (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    const data = {
      ...bulletin,
      recaptchaToken: recaptchaValue,
      reason,
    };

    if (reason === 'other') {
      data.otherText = otherReason;
    }
    onReport(data);

    recaptchaRef.current.reset();
    onRecaptcaChange(null);
  };

  const onRecaptchaChange = (token) => {
    onRecaptcaChange(token);
  };

  const handleCloseError = (event, _reason) => {
    if (_reason === 'clickaway') {
      return;
    }

    onErrorClose();
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={1}
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography
          component="span"
          variant="body1"
        >
          {t('report_personal_data')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <Grid
            container
            spacing={1}
          >
            <Grid item>
              <TextField
                label={t('problem')}
                id="standard-select-currency"
                select
                value={reason}
                onChange={handleReasonChange}
              >
                {reasons.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {
              reason === 'other'
                ? (
                  <Grid item>
                    <TextField
                      label={t('state_problem')}
                      value={otherReason}
                      onChange={handleOtherReasonChange}
                    />
                  </Grid>
                )
                : null
            }

            <Grid item xs={12}>
              <Box mt={2}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // FOR TESTING PURPOSES
                  sitekey="6LeY0sIZAAAAAFlWFXAA4uwARqCr6LMraXuhMeuB"
                  onChange={onRecaptchaChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                rel="noreferrer"
                target="_blank"
                disableElevation
                className={classes.button}
                onClick={reportBulletin}
                size="large"
                disabled={!valid}
              >
                {t('notify')}
              </Button>

              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={error !== null}
                autoHideDuration={6000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {error}
                </Alert>
              </Snackbar>
            </Grid>

            <Grid item xs={12}>
              <Typography
                component="span"
                style={{ fontSize: '0.9rem' }}
              >
                {t('report_manually')}
              </Typography>
              &nbsp;
              <Link
                variant="body1"
                href="https://forms.gle/LhipsYfcWwhmeQ5L6"
                color="primary"
                target="_blank"
                underline="always"
                style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
              >
                {t('this_form')}
              </Link>

            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

ReportPersonalData.propTypes = {
  bulletin: PropTypes.objectOf(PropTypes.string).isRequired,
  onReport: PropTypes.func.isRequired,
};
