import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ButtonBlue from '../ButtonBlue';
import useCurrentLocation from '~/hooks/useCurrentLocation';
import { abroadCheckin, getCookie } from '~/api/AbroadCheckin';
import { cookieAbroadSupport } from '../../../globals';
import { isSundayToday } from '~/helpers/countersTexts';
import Loading from '~/components/Loading';

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
};

function isSupportedAvailable() {
  if (isSundayToday && !getCookie(cookieAbroadSupport)) {
    return false;
  }

  return true;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& button': {
      width: '100%',
    },
  },
  loading: {
    '& div': {
      color: '#fff',
    },
  },
  error: {
    fontSize: '0.9rem',
    color: theme.palette.error.main,
    marginTop: '10px',
  },
}));

export default function SupportBelarusButton() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activated, setActivated] = useState(isSupportedAvailable());
  const [loading, setLoading] = useState(false);

  const { location: currentLocation, error: currentError } = useCurrentLocation(geolocationOptions);
  console.log(currentError);

  const locationData = {
    location: !currentError ? currentLocation : null,
  };
  console.log(locationData);

  const callbackClick = () => {
    setLoading(false);
    setActivated(true);
  };

  const handleClick = () => {
    setLoading(true);
    abroadCheckin(locationData, callbackClick);
  };

  const buttonTextKey = !isSundayToday || !activated ? 'support_button' : 'support_button_disabled';

  return (
    <Box className={classes.root}>
      <ButtonBlue
        onClick={handleClick}
        disabled={activated}
      >
        {loading ? (
          <Box className={classes.loading}><Loading /></Box>
        ) : t(buttonTextKey)}
      </ButtonBlue>
      {isSundayToday && currentError
      && (
        <Box className={classes.error}>
          <sup>*</sup>
          {t('enable_geolocation')}
        </Box>
      )}
    </Box>
  );
}
