import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
} from 'react-share';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  shareContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '4px',
    justifyContent: (props) => props.align,
  },
  shareButton: {
    border: 'none',
    marginBottom: '4px',
    padding: '0px',
    color: 'inherit',
    cursor: 'pointer',
  },
}));

export default function ShareButtons({
  align, description, url, title, share,
}) {
  const classes = useStyles({ align });

  const shareButtons = {
    facebook: (key) => (
      <FacebookShareButton
        url={url}
        resetButtonStyle={false}
        quote={title}
        key={key}
        className={clsx(classes.shareButton, 'facebook social')}
      >
        <FontAwesomeIcon icon={['fab', 'facebook-f']} color="white" />
      </FacebookShareButton>
    ),
    odnoklassniki: (key) => (
      <OKShareButton
        title={title}
        description={description}
        key={key}
        url={url}
        resetButtonStyle={false}
        className={clsx(classes.shareButton, 'odnoklassniki social')}
      >
        <FontAwesomeIcon icon={['fab', 'odnoklassniki']} color="white" />
      </OKShareButton>
    ),
    telegram: (key) => (
      <TelegramShareButton
        url={url}
        resetButtonStyle={false}
        title={title}
        key={key}
        className={clsx(classes.shareButton, 'telegram social')}
      >
        <FontAwesomeIcon icon={['fab', 'telegram-plane']} color="white" />
      </TelegramShareButton>
    ),
    twitter: (key) => (
      <TwitterShareButton
        url={url}
        resetButtonStyle={false}
        title={title}
        key={key}
        className={clsx(classes.shareButton, 'twitter social')}
      >
        <FontAwesomeIcon icon={['fab', 'twitter']} color="white" />
      </TwitterShareButton>
    ),
    viber: (key) => (
      <ViberShareButton
        url={url}
        resetButtonStyle={false}
        title={title}
        key={key}
        className={clsx(classes.shareButton, 'viber social')}
      >
        <FontAwesomeIcon icon={['fab', 'viber']} color="white" />
      </ViberShareButton>
    ),
    vk: (key) => (
      <VKShareButton
        url={url}
        resetButtonStyle={false}
        title={title}
        key={key}
        noParse
        className={clsx(classes.shareButton, 'vk social')}
      >
        <FontAwesomeIcon icon={['fab', 'vk']} color="white" />
      </VKShareButton>
    ),
  };

  return (
    <Box mt={2}>
      <Typography className={classes.shareContainer}>
        {share.map((btn) => shareButtons[btn] ? shareButtons[btn](`share_${btn}`) : null)}
      </Typography>
    </Box>
  );
}
ShareButtons.defaultProps = {
  align: 'center',
  title: 'Голос: #ягуляю #supportbelarus #уНасЕстьГолос',
  description: 'Новый проект Голоса #ягуляю - честно посчитаем всех, кто гуляет! Show solidarity with the people of Belarus via the new Voice project - #supportbelarus.',
  share: ['vk', 'facebook', 'twitter', 'telegram', 'viber'],
};

ShareButtons.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  share: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
};
