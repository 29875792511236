import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, Button, Grid, List, ListItem, Tooltip, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { getPollingStationNumber } from '~/helpers/numberFormatter';
import { get as getStatistics } from '~/services/ResultsService';
import getAll from '~/helpers/electionChoices';
import Link from '~/components/Link.jsx';
import Loading from '~/components/Loading';
import PollingStationStepper from './PollingStationStepper';
import { getPollingStations, getWhitelist } from '~/redux/selectors';
import StationIconBlue from '~/assets/images/station-icon-blue.svg';
import StationIconRed from '~/assets/images/station-icon-red.svg';
import StationIconGray from '~/assets/images/station-icon-gray.svg';
import ShareButtons from '~/components/ShareButtons';
import ResultLinks from '~/components/ResultLinks';
import ReportProblemLink from '~/components/ReportProblemLink';
import { resultsUrl } from '../../../../globals';

const CandidateTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#F20C0C',
    border: '1px solid #F20C0C',
    borderRadius: '0',
    fontSize: '0.9rem',
    maxWidth: '182px',
    padding: theme.spacing(2),
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  pollingStationSummary: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  headerContainer: {
    display: 'grid',
    [theme.breakpoints.up('xs')]: {
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      gridTemplateColumns: 'auto auto',
      justifyContent: 'left',
      gridTemplateAreas: `"stepper ."
      "pollingStationName pollingStationStatus"`,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'auto auto auto',
      justifyContent: 'left',
      gridColumnGap: theme.spacing(2),
      gridTemplateAreas: '"pollingStationName pollingStationStatus stepper"',
      alignItems: 'center',
    },
  },
  stepper: {
    gridArea: 'stepper',
  },
  pollingStationName: {
    gridArea: 'pollingStationName',
  },
  pollingStationStatus: {
    gridArea: 'pollingStationStatus',
  },
  locality: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  address: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.75rem',
      lineHeight: '1.1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
      lineHeight: 'inherit',
    },
  },
  statisticsHeader: {
    fontWeight: 600,
    overflowWrap: 'break-word',
    fontSize: '0.875rem',
  },
  headerWithPhoto: {
    fontWeight: 600,
    fontSize: '0.875rem',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap',
  },
  candidateList: {
    '& .MuiGrid-item': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('xs')]: {
      '& li:first-of-type': {
        padding: 0,
      },
    },
  },
  candidateListItem: {
    position: 'relative',
    padding: '4px 0',
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
      lineHeight: '1.2rem',
    },
  },
  candidateName: {
    textAlign: 'left !important',
  },
  highlightBox: {
    display: 'none',
    fontWeight: 'bold',
    cursor: 'default',
  },
  exclamationMark: {
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  highlightExplanation: {
    display: 'none',
  },
  highlightCandidate: {
    color: '#F20C0C',
    '& $highlightBox': {
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
      },
    },
    '& $highlightExplanation': {
      [theme.breakpoints.up('xs')]: {
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },
  closeButton: {
    borderRadius: 0,
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'initial',
    [theme.breakpoints.up('xs')]: {
      height: '50px',
      width: '50px',
    },
    [theme.breakpoints.up('md')]: {
      height: '60px',
      width: '60px',
    },
  },
  closeIcon: {
    fontSize: '32px',
    color: 'white',
  },
}));

const PollingStationSummary = ({
  onClose,
  pollingStationIds,
  pollingStations,
  whitelist,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [pollingStationIndex, setPollingStationIndex] = useState(0);

  const [statisticsLoaded, setStatisticsLoaded] = useState(false);
  const [statistics, setStatistics] = useState(null);

  const pollingStationId = pollingStationIds[pollingStationIndex];

  const address = pollingStations && pollingStationId && pollingStations.length > 0
    ? pollingStations.find((station) => station.code === pollingStationId) : null;

  const candidates = getAll();
  useEffect(() => {
    setPollingStationIndex(0);
  }, [pollingStationIds]);

  useEffect(() => {
    async function loadStatistics() {
      if (!pollingStationId) {
        return;
      }
      setStatisticsLoaded(false);
      try {
        const newStatistics = await getStatistics(pollingStationId);
        setStatistics(newStatistics);
      } catch (e) {
        // TODO error handling
      } finally {
        setStatisticsLoaded(true);
      }
    }

    loadStatistics();
  }, [pollingStationId]);

  if (pollingStationIds.length === 0) {
    return null;
  }

  const getIcon = (id) => {
    if (whitelist.forgery.includes(id)) {
      return StationIconRed;
    }

    if (whitelist.truth.includes(id)) {
      return StationIconBlue;
    }

    return StationIconGray;
  };

  const getHighlightMessage = (candidate) => (
    statistics.choices[candidate.id]
      && (statistics.choices[candidate.id].photoVoices
        > statistics.choices[candidate.id].officialVotes)
      ? t('more_photos_than_official')
      : t('more_ballots_than_official')
  );

  return (
    <Box className={classes.pollingStationSummary}>
      <Button
        variant="contained"
        color="primary"
        className={classes.closeButton}
        onClick={onClose}
        disableElevation
      >
        <CloseIcon className={classes.closeIcon} />
      </Button>
      {address ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography className={classes.pollingStationName} variant="h4" component="h3">
              {`${t('участок')} ${getPollingStationNumber(address.code)}`}
            </Typography>
            {whitelist !== null ? (
              <img
                height="22"
                width="22"
                className={classes.pollingStationStatus}
                src={getIcon(pollingStationId)}
                alt="статус участка"
              />
            ) : null}
            {pollingStationIds.length > 1 ? (
              <PollingStationStepper
                className={classes.stepper}
                activeStep={pollingStationIndex}
                steps={pollingStationIds.length}
                onBack={() => setPollingStationIndex(pollingStationIndex - 1)}
                onNext={() => setPollingStationIndex(pollingStationIndex + 1)}
              />
            ) : null}
          </Box>
          <Typography className={classes.locality}>
            {address
              ? (
                <>
                  {address.locality}
                </>
              ) : null}

            {address.region
              ? (
                <>
                  <span>, </span>
                  {address.region}
                  <span>{` ${t('область')}`}</span>
                </>
              ) : null}
          </Typography>
          <Typography className={classes.address}>
            {address.address}
          </Typography>
        </>
      ) : <Loading />}

      {statisticsLoaded ? (
        <>
          <List disablePadding className={classes.candidateList}>
            <ListItem disableGutters divider>
              <Grid container justify="flex-end" spacing={1}>
                <Grid item xs={4} md={3}>
                  <Typography className={classes.statisticsHeader} component="span">
                    <Typography className={clsx(classes.headerWithPhoto)} component="span">
                      {t('voices_plural')}
                      &nbsp;/&nbsp;
                    </Typography>
                    <Typography className={clsx(classes.headerWithPhoto)} component="span">
                      {t('with_photo')}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Typography className={classes.statisticsHeader} component="span">
                    {t('according_to_CEC')}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {candidates.map((x) => (x.id !== 'ignore'
              ? (
                <ListItem
                  key={x.id}
                  disableGutters
                  divider
                  className={clsx(classes.candidateListItem, {
                    [classes.highlightCandidate]:
                      statistics.choices[x.id]
                      && statistics.choices[x.id].officialVotes !== null
                      && (statistics.choices[x.id].registered
                        > statistics.choices[x.id].officialVotes),
                  })}
                >
                  <Grid container alignItems="baseline" spacing={1}>
                    <Grid item xs={4} md={6} className={classes.candidateName}>
                      <Typography variant="h5">
                        {t(x.id)}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} md={3}>
                      <Typography component="span">
                        {statistics.choices[x.id]
                          ? statistics.choices[x.id].registered.toLocaleString()
                          : '-'}
                              &nbsp;/&nbsp;
                        {statistics.choices[x.id]
                          ? statistics.choices[x.id].photoVoices.toLocaleString()
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography component="span">
                        {statistics.choices[x.id] && statistics.choices[x.id].officialVotes !== null
                          ? statistics.choices[x.id].officialVotes.toLocaleString()
                          : '-'}
                      </Typography>
                            &nbsp;
                      <CandidateTooltip
                        className={classes.highlightBox}
                        title={getHighlightMessage(x)}
                        placement="top-end"
                      >
                        <Typography className={classes.exclamationMark}>!</Typography>
                      </CandidateTooltip>
                    </Grid>

                    <Grid item xs={12} className={classes.highlightExplanation}>
                      <Typography align="left" style={{ fontSize: '0.8rem' }}>
                        {getHighlightMessage(x)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              )
              : null))}
          </List>
        </>
      ) : <Loading />}

      {
        statisticsLoaded
        && (
          <Box mt={2}>
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                <ResultLinks statistics={statistics} />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  align="right"
                >
                  <ReportProblemLink />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )
      }

      <ShareButtons url={resultsUrl} />

      <Box mt={2}>
        <Typography
          component="div"
          align="center"
        >
          <Link
            color="textPrimary"
            variant="body1"
            underline="always"
            to={`pollingStations/${pollingStationId}`}
          >
            <span>{t('pollingStationDetails')}</span>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

PollingStationSummary.propTypes = {
  pollingStationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pollingStations: getPollingStations(state),
  whitelist: getWhitelist(state),
});

export default connect(mapStateToProps)(PollingStationSummary);
