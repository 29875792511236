import qs from 'qs';

import { DEFAULT_ZOOM } from './constants';

export const hasQuery = () => window.location.href.split('?').length > 1;

export const getQuery = () => {
  const [, search] = window.location.href.split('?');
  return qs.parse(search);
};

export const pushState = (query) => {
  const currentQuery = qs.stringify(getQuery());
  const nextQuery = qs.stringify(query);
  if (currentQuery !== nextQuery) {
    window.history.pushState(query, '', `?${nextQuery}`);
  }
};

export const listenPopState = ({ mapInstance, openStation }) => {
  window.addEventListener('popstate', () => {
    const {
      lat, lon, z = DEFAULT_ZOOM, station,
    } = getQuery();
    if (lat && lon) {
      if (mapInstance.balloon.isOpen()) {
        mapInstance.balloon.close();
      }
      mapInstance.setCenter([lon, lat], z);
    }
    if (station) {
      openStation(station);
    }
  });
};
