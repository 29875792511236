const en = {
  translations: {
    polling_stations: 'Polling stations',
    результаты: 'Preliminary election results',
    voice: 'voice',
    with_photo: 'photos',
    according_to_CEC: 'according to PEC',
    voters: 'voters',
    unique_confirmed: 'unique confirmed votes on the "Voice" platform',
    'фото в системе': 'verified ballot photos on the "Voice" platform',
    social_media_follow: 'Follow us on social media.',
    tell_friends: 'Tell your friends about us!',
    пользовательское: 'Terms of service',
    faq: 'Frequently Asked Questions',
    stations: 'stations',
    'официальная явка': 'voter turnout according to PEC',
    according_to_observers: 'voter turnout according to observer',
    honest_people_hashtag: '#weAreHonestPeople',
    we_have_voice_hashtag: '#weHaveAVoice',
    all: 'All',
    dmitriyev: 'Dmitriyeu',
    kanopatskaja: 'Kanapatskaya',
    lukashenko: 'Lukashenka',
    tihanovkaja: 'Tsikhanouskaya',
    cherechen: 'Cherachen',
    against: 'Against all',
    corrupted: 'Spoilt ballot',
    ignore: 'Did not vote',
    dmitriyevAccusative: 'for Dmitriyeu',
    kanopatskajaAccusative: 'for Kanapatskaya',
    lukashenkoAccusative: 'for Lukashenka',
    tihanovkajaAccusative: 'for Tsikhanouskaya',
    cherechenAccusative: 'for Cherachen',
    againstAccusative: 'against all',
    corruptedAccusative: 'This ballot was spoilt',
    ignoreAccusative: '',
    pollingStationDetails: 'learn more',
    voices_plural: 'voices',
    voices_plural_stats: 'voices',
    voted_for: 'This vote was casted',
    back_side: 'The back',
    honest_stations: 'The victory of the candidate coincides in the official results of the commission and the "Voice" data',
    dishonest_stations: 'There are more votes for a candidate in the "Voice" platform than in the official report (falsification)',
    not_enough_data: 'Insufficient data from the polling stations to conclude about the election commission\'s honesty',
    total_num_of_voices: 'total number of votes on the "Voice" platform',
    участок: 'Polling station',
    all_stations: 'All polling stations',
    область: 'region',
    title: 'Online platform "Voice". This time your voice matters!',
    processing_monitored: 'Voter turnout according to observers is being processed',
    processing_voters: 'Voter turnout is being processed',
    processing_official_numbers: 'The final results were not posted or photographed',
    processing_official_numbers_table: 'No data',
    protect_now: 'Ensure your voice is heard!',
    more_ballots_than_official: 'There are more "Voices" than PEC announced votes',
    more_photos_than_official: 'There are more photos of ballots than PEC announced votes',
    destroyed_ballot: 'This ballot was spoilt',
    summary_results: 'Voting results',
    detailed_report_button: 'Detailed report',
    results_button: 'PEC results',
    summary_1: 'The elections are invalid, because falsifications were detected at every third polling station.',
    summary_2: 'The final figures announced by the CEC differ significantly from the precincts\' official results.',
    summary_3: 'Alyaksandr Lukashenka cannot be considered the legitimate president of Belarus.',
    summary_4: 'A large number of votes cast for Sviatlana Tsikhanouskaya were stolen by precinct commissions.',
    chatbot_send_photo: 'Send a photo of your ballot to the chatbot of the "Voice" platform',
    detailed_report: '<bold>The detailed report<bold>',
    detailed_report_from: 'created by the "Voice" platform and the "Honest People" initiative can be found',
    here: 'here',
    no_photos: 'Photos of the ballots are being processed',
    no_photos_available: 'There are no photos for this candidate',
    sensitive_data: 'The photo is hidden because it may contain the voter\'s passport or other sensitive data. The "Voice" platform possesses the original photo. There are a total of 45,865 such photos from {{photos}} (less than 10%)',
    photo_quarantined: 'The photo is temporarily hidden, as it is undergoing additional processing',
    report_personal_data: 'We have hidden personal data (passport or face) from most of the photographs containing it. If you still notice a photo with personal information on it or the one incorrectly processed, let us know by clicking on the "Hide photo" button below.',
    PEC_results: 'PEC results',
    send_results_photo: 'Send PEC results',
    station_code: 'Polling station number',
    photos_disclaimer: 'Photos of the PEC results are from independent observers of the "Honest People" and "Zubr" initiatives, as well as from open sources. We estimate the accuracy of the data to be 98%. If you find an inaccuracy or error, please let us know via',
    this_form: 'this form',
    site_title: 'Platform "Voice" results: find your station!',
    site_description: 'We will help to protect your choice and show the results, calculated not by commissions, but by voters. Go to Viber, Telegram, tell us about your choice!',
    error_notify: 'Report a problem',
    notify: 'Hide photo',
    notify_personal_data: 'Contains personal data',
    notify_incorrect_data: 'Incorrect candidate',
    notify_other: 'Other problem',
    problem: 'Problem',
    state_problem: 'Describe problem',
    report_manually: 'If for some reason the form above does not work, submit the error manually using',
    missing_photo1: 'Can\'t find your photo? ',
    missing_photo2: 'The "Voice" platform contains ',
    missing_photo3: '{{registered}} voices',
    missing_photo4: ' not linked to any polling station, of which {{photoVoices}} are with a photo.',
    // walking_call_header: 'Go out for a walk in good company',
    walking_call_header: 'SEPTEMBER 20 — BELARUS SOLIDARITY DAY',
    solid_day_announce_big: '"Voice" joins the global Belarus Solidarity Day on <bold>September\u00A020</bold>. Join us to support our country by clicking the "Support Belarusians" button.',
    solid_day_announce_small: 'We have temporarily suspended the chatbot command /ягуляю while we are conducting the poll in support of the Coordination Council and for the duration of the Solidarity Day.<br /><br />Click the button below to support the Belarusians on September 20.',
    button_support_belarus: 'SUPPORT BELARUSIANS',
    walking_call_button: 'How does it work?',
    us_already: 'Registered with "Voice"',
    walking_today: '{{GoOut}} for a walk in Belarus on {{Sunday}}',
    walking_home: '{{Walk}} in Belarus on {{Sunday}}',
    image_author: 'illustration by:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Step 1: Register with "Voice"',
    walking_title_step_2: 'Step 2: Tell the chat bot you\'re out for a walk',
    walking_title_step_3: 'Step 3: Share your walk with friends',
    walking_activate_bot: 'Activate the bot before your walk',
    walking_activate_bot_desc_1: 'Before you head out for a walk, activate the chat bot by sending the message <bold>/ягуляю</bold>. This way "Voice" will know that on this specific day you joined the protests. If you would like to go out but you cannot – send the message anyway.',
    walking_activate_bot_desc_2: 'Participation in legal large-scale marches, local protests, solidarity chains, any initiatives, including a walk in the yard and a conversation with your neighbors — your protest can take any form. The important thing is that you\'re active, and that our chat bot knows about it. The platform does not need your personal data — we would only like to count the participants in the protests.',
    walking_activate_bot_desc_3: 'We will set up a count every Sunday, as well as on special days, which will be announced separately',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Use the hashtag #ягуляю',
    walking_use_hashtag_desc_1: 'The more there are of us, the louder is our voice. The authorities will have a much harder time saying that we cannot be heard. Take pictures of yourself during the walk, post them in social networks, and use the hashtag <bold>#ягуляю</bold>.',
    walking_use_hashtag_desc_2: 'Tell everyone you know about the project. Our counter is the proof of our truth and our victory.',
    walking_how_title: 'How it works',
    walking_how_desc_1: '<bold>#ягуляю</bold> is a new special project by "Voice". This time we would like to count the Belarusians who go out to protests to stand for their freedom and new fair elections. We would like to show the world the real picture of our fight for justice.',
    walking_how_desc_2: '<bold>#ягуляю</bold> will count everyone: those tho are out for a legal walk in Minsk and other large cities, those who are out for a walk in remote corners of Belarus to support protesters, those who objectively cannot walk but would like to stand against lawlessness.',
    walking_how_desc_3: 'It\'s anonymous and safe. Anyone can join. Even those who would like to support the protest but have not yet resolved to do it openly.',
    walking_how_desc_4: 'More than <bold>1 200 000</bold> people have already registered with "Voice". Now we are re-opening the registration on the platform.',
    walking_how_desc_5: 'Installed the bot? Share our idea with your friends!',
    walking_how_desc_6: '<bold>If you have already registered with the chat bot, proceed to the next step.</bold>',
    walking_how_desc_7: '<bold>If you are registering for the first time, press the button for your favorite messenger app and follow the chat bot instructions. On the first step, confirm that you have provided your phone number (it will be securely encrypted).</bold>',
    no_messenger: 'If you do not have a messenger app',
    faq_answers: 'Answers to frequently asked questions',
    go_hashtag: '#ягуляю',
    support_belarus_call_header: 'Support people protesting in\u00A0Belarus on {{Sunday}}',
    support_belarus_background_image: 'Photo of Stela with thousands of protesters in\u00A0Minsk',
    support_belarus_counters_title_total: 'World support for\u00A0Belarus',
    support_belarus_counters_title_total_walking: '{{Protest}} in Belarus on\u00A0{{Sunday}}',
    support_button_header: 'Support Belarus Today',
    support_button_desc: 'Just press the "SUPPORT THE PROTESTERS" button on\u00A0Sunday, and we will show to people in Belarus that they are not alone and that the whole world supports those who walk today against the regime and for democracy.',
    support_button: 'Support the protesters',
    support_button_temp: 'Come back on Sunday to support Belarus',
    support_header: 'Why Does It Matter?',
    support_desc_0: 'Every Sunday thousands of Belarusians take to the streets to peacefully protest against the falsified elections by the "last dictator of Europe" Alyaksandr Lukashenka.',
    support_desc_1: 'We created a unique platform, "Voice", to count the number of people attending these protests. This time we add a possibility for the whole world to show their support of the will of Belarusians to live in a law-bound, democratic state.',
    support_desc_2: 'We invite you to show solidarity with the people of Belarus, who have long been subjected to terror and repression for their views by the country‘s authoritarian government.',
    support_icons_header: 'Why Are People Protesting?',
    support_icons_title_0: 'The country has been governed by the "last dictator of Europe" A. Lukashenka for the past 26 years.',
    support_icons_title_1: 'All Belarusian referendums and elections after 1994 were declared illegitimate by the OSCE.',
    support_icons_title_2: 'During the election campaign and in the days following the election, over 7 000 people were brutally arrested at peaceful protests objecting the falsified results.',
    support_icons_title_3: 'Illegal mass arrests, fabricated crimes, extremely high and unreasonable fines, abuse of power of law enforcement agencies (kidnapping, blackmail, aggression, and violence up to murder) – this is a present-day reality in Belarus.',
    support_icons_desc: 'This fundamentally contradicts the idea of free, transparent, democratic elections and leaves no doubt about them being illegal and falsified. Citizens of Belarus are deprived of basic human rights: freedom of speech, the right to vote, or the right to freedom and personal inviolability. There is no presumption of innocence, and independent, fair courts are non-existent in Belarus. Furthermore, police can hinder the right of citizens of Belarus to timely medical care.',
    support_flags: 'Image of the national white-red-white flags',
    support_share_desc: 'Share this message to allow other people support Belarus',
    support_icon_0: 'Gun icon',
    support_icon_1: 'Crossed eye icon',
    support_icon_2: 'Prison man icon',
    support_icon_3: 'Libra icon',
    support_belarus: 'Support Belarus',
    photo_by: 'photo by ',
    go_eng_hashtag: '#supportbelarus',
    support_button_disabled: 'Thanks for your support!',
    enable_geolocation: 'Please enable geolocation in your browser or on your device to let us know your region.',
    walking_info: 'On\u00A0Sunday, September\u00A013, there was no internet access in Belarus for many hours, which prevented many of our users from sending a command to\u00A0the chatbot. Therefore, we will continue accepting your commands until 12.00\u00A0on\u00A0Monday. Hurry up, send /ягуляю to the "Voice" platform chatbot!',
    walking_result_title: 'We went for a walk',
    walking_result_supporters_title: 'Our Supporters',
    walking_result: 'The peaceful protests on Sunday, September\u00A013, were attended by <bold>Belarusians all over the country — from more than 400 cities, villages and towns</bold>, and were supported by caring residents of <bold>more than 1400 cities and villages from 87 countries!</bold><br /><br />The map displays the number of people who went for a walk (the redder the colour is, the more people were there).',
    walking_by: 'in Belarus',
    walking_eu: 'in Europe',
    walking_world: 'in the world',
  },
};
export default en;
